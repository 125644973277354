import { useTranslation } from 'react-i18next';
import { Button, DialogActions, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import useSharedStyles from 'styles/useSharedStyles';
import { Firmware } from 'features/RemoteManagement/types/Types';
import CONFIG from 'config';
import { useEffect, useState } from 'react';

interface Props {
  handleNextStep: () => void;
  handleBackStep: () => void;
}

const DownloadFirmware = ({ handleNextStep, handleBackStep }: Readonly<Props>) => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const backStr = t('Shared.Back');
  const headerStr = t('Site.Configure.RegisterGateway.DownloadFirmware.Header');
  const subheaderStr = t('Site.Configure.RegisterGateway.DownloadFirmware.Subheader');
  const iUpdatedFirmwareStr = t('Site.Configure.RegisterGateway.DownloadFirmware.IUpdatedFirmware');
  const firmwareUrl = CONFIG.openApiEndpoint + '/firmware/download/';
  const [gwFirmware, setGwFirmware] = useState<Firmware>({ name: 'Fetching...', version: 0, updateLog: [] });
  const versionStr = t('Shared.Version', { version: gwFirmware.version });
  const sanitizedFirmwareName = gwFirmware.name.split('.')[0];
  const formattedFirmwareUrl = firmwareUrl + gwFirmware.name;

  const deviceModel = 'IXGW-GW';
  const latestFirmwareUrl = CONFIG.openApiEndpoint + '/firmware/latest';

  useEffect(() => {
    const fetchFirmware = async () => {
      const firmwareVersionCheck = await fetch(latestFirmwareUrl);
      const latestVersion = await firmwareVersionCheck.json();

      setGwFirmware({
        name: latestVersion[deviceModel]?.enhanced.name,
        version: latestVersion[deviceModel]?.enhanced.version,
        updateLog: []
      });
    };

    fetchFirmware();
  }, []);

  const handleDownloadClick = async () => {
    const downloadResponse = await fetch(formattedFirmwareUrl);

    const url = await downloadResponse.text();

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', gwFirmware.name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  return (
    <Grid container sx={sharedStyle.gridContainer}>
      <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
        <Grid item>
          <Typography variant="h6">{headerStr}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{subheaderStr}</Typography>
        </Grid>
        <Grid item>
          <Button sx={[sharedStyle.infoBox, sharedStyle.downloadButton]} onClick={handleDownloadClick}>
            <Grid container sx={sharedStyle.downloadButton.grid}>
              <Grid item sx={sharedStyle.downloadButton.grid.iconContainer}>
                <DownloadIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textPrimary">
                  {sanitizedFirmwareName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {versionStr}
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <DialogActions sx={sharedStyle.common.padding.none}>
          <Button variant="text" onClick={handleBackStep}>
            {backStr}
          </Button>
          <Button variant="contained" onClick={handleNextStep}>
            {iUpdatedFirmwareStr}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default DownloadFirmware;
