import {
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  Typography,
  DialogActions,
  Grid,
  SxProps,
  Theme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SyntheticEvent } from 'react';
import useSharedStyles from 'styles/useSharedStyles';

interface Props {
  handleNextStep: () => void;
}

const Introduction = ({ handleNextStep }: Readonly<Props>) => {
  const readyStatus: {
    [key: string]: boolean;
    intercomsConnected: boolean;
    modelMacWritten: boolean;
    deviceInfoListed: boolean;
  } = {
    intercomsConnected: false,
    modelMacWritten: false,
    deviceInfoListed: false
  };

  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const continueStr = t('Shared.Continue');
  const completeChecklistStr = t('Site.Configure.RegisterGateway.Introduction.Error.Checklist');
  const introductionChecklistStr = t('Site.Configure.RegisterGateway.Introduction.Checklist');

  // write a yup schema that validates all three checkboxes are true
  const introductionValidation = Yup.object().shape({
    intercomsConnected: Yup.boolean().required().isTrue(),
    modelMacWritten: Yup.boolean().required().isTrue(),
    deviceInfoListed: Yup.boolean().required().isTrue()
  });

  return (
    <Formik
      initialValues={readyStatus}
      validationSchema={introductionValidation}
      onSubmit={handleNextStep}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, touched, setTouched, handleChange }) => {
        const handleFieldChange = (e: SyntheticEvent<Element, Event>, checked: boolean) => {
          if (checked) {
            Object.keys(readyStatus).forEach((key) => {
              setTouched({ [key]: false });
            });
          }
          handleChange(e);
        };

        const allSelected = Object.keys(readyStatus).every((key) => values[key]);

        const defaultCheckboxStyle: SxProps<Theme>[] = [
          sharedStyle.outlinedField,
          sharedStyle.common.margin.none,
          sharedStyle.common.width.full
        ];
        const selectedCheckboxStyle = defaultCheckboxStyle.concat(sharedStyle.outlinedField.selected);
        const allSelectedCheckboxStyle = defaultCheckboxStyle.concat(sharedStyle.outlinedField.success);

        const intercomsConnectedStyle = allSelected
          ? allSelectedCheckboxStyle
          : values['intercomsConnected']
          ? selectedCheckboxStyle
          : defaultCheckboxStyle;
        const modelMacWrittenStyle = allSelected
          ? allSelectedCheckboxStyle
          : values['modelMacWritten']
          ? selectedCheckboxStyle
          : defaultCheckboxStyle;
        const deviceInfoListedStyle = allSelected
          ? allSelectedCheckboxStyle
          : values['deviceInfoListed']
          ? selectedCheckboxStyle
          : defaultCheckboxStyle;

        return (
          <Form>
            <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
              <Grid item>
                <Typography variant="h6">{introductionChecklistStr}</Typography>
              </Grid>
              <Grid item>
                <FormGroup>
                  <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
                    <Grid item>
                      <FormControlLabel
                        sx={intercomsConnectedStyle as SxProps<Theme>}
                        name="intercomsConnected"
                        control={<Checkbox />}
                        onChange={handleFieldChange}
                        label={t('Site_Wizard_Check_Step_1')}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        sx={modelMacWrittenStyle as SxProps<Theme>}
                        name="modelMacWritten"
                        control={<Checkbox />}
                        onChange={handleFieldChange}
                        label={t('Site_Wizard_Check_Step_2')}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        sx={deviceInfoListedStyle as SxProps<Theme>}
                        name="deviceInfoListed"
                        control={<Checkbox />}
                        onChange={handleFieldChange}
                        label={t('Site_Wizard_Check_Step_3')}
                      />
                    </Grid>
                    <Grid item sx={sharedStyle.errorMessage.fixedHeight}>
                      {Object.keys(errors).length > 0 &&
                        Object.keys(touched).length == Object.keys(readyStatus).length && (
                          <Typography sx={sharedStyle.errorMessage}>{completeChecklistStr}</Typography>
                        )}
                    </Grid>
                  </Grid>
                </FormGroup>
                <DialogActions sx={sharedStyle.common.padding.none}>
                  <Button type="submit" variant="contained">
                    {continueStr}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Introduction;
