import useIpV4AddressValidationSchema from '../Shared/IpV4Address';
import useIpV6AddressValidationSchema from '../Shared/IpV6Address';
import * as Yup from 'yup';
import StringUtils from 'shared/utils/StringUtils';
import { RegexDomainName } from 'features/RemoteManagement/types/Types';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import useSubnetMaskValidationSchema from '../Shared/SubnetMask';
import { useAppSelector } from 'store/hooks';

export interface IIPAddressFields {
  [key: string]: any;
  networkSettings: {
    [key: string]: string | number | null | undefined;
    hostName?: string | null;
    subnetMask?: string | null;
    ipVersion?: number | null;
    ipAddressMethod?: number | null;
    ipV4Address?: string | null;
    ipV4DefaultGateway?: string | null;
    ipV6Address?: string | null;
    ipV6DefaultGateway?: string | null;
    ipV4PrimaryDns?: string | null;
    ipV4SecondaryDns?: string | null;
    ipV6PrimaryDns?: string | null;
    ipV6SecondaryDns?: string | null;
    ipV4MulticastAddress?: string | null;
    ipV6MulticastAddress?: string | null;
  };
  gatewaySettings: {
    [key: string]: number | undefined;
    communicationMethod: number | undefined;
  };
}

const useIPAddressValidationSchema = (targetDevicePublicId: string) => {
  const { t } = useTranslation();
  const fieldErrorMaxLen = t('Field.Error.MaxLen');
  const hostNameTitle = t('AdvancedSettings_HostName_Title');
  const ipV4DefaultGatewayTitle = t('AdvancedSettings_IpV4DefaultGateway_Title');
  const ipV4PrimaryDnsTitle = t('AdvancedSettings_IpV4PrimaryDns_Title');
  const ipV4SecondaryDnsTitle = t('AdvancedSettings_IpV4SecondaryDns_Title');
  const ipV4MulticastAddressTitle = t('AdvancedSettings_IpV4MulticastAddress_Title');
  const ipV4AddressTitle = t('AdvancedSettings_IpV4Address_Title');
  const ipV6AddressTitle = t('AdvancedSettings_IpV6Address_Title');
  const ipV6DefaultGatewayTitle = t('AdvancedSettings_IpV6DefaultGateway_Title');
  const ipV6PrimaryDnsTitle = t('AdvancedSettings_IpV6PrimaryDns_Title');
  const ipV6SecondaryDnsTitle = t('AdvancedSettings_IpV6SecondaryDns_Title');
  const ipV6MulticastAddressTitle = t('AdvancedSettings_IpV6MulticastAddress_Title');
  const subnetMaskTitle = t('AdvancedSettings_SubnetMask_Title');
  const hostNameErrorInvalid = t('Field.Error.Invalid', { field: hostNameTitle });
  const subnetMaskErrorRequiredStr = t('Field.Error.Required', { field: subnetMaskTitle });
  const ipVersionTitle = t('AdvancedSettings_IpVersion_Title');
  const ipVersionRequiredStr = t('Field.Error.Required', { field: ipVersionTitle });
  const ipVersionInvalidStr = t('Field.Error.Invalid', { field: ipVersionTitle });
  const ipV4RequiredStr = t('Field.Error.Required', { field: ipV4AddressTitle });
  const ipV6RequiredStr = t('Field.Error.Required', { field: ipV6AddressTitle });
  const ipV4UniquePerSiteStr = t('Field.Error.UniquePerSite', { field: ipV4AddressTitle });
  const ipV6UniquePerSiteStr = t('Field.Error.UniquePerSite', { field: ipV6AddressTitle });
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const takenIpV4s: string[] = Object.values(deviceList)
    .filter(
      (device) =>
        device.publicId !== targetDevicePublicId &&
        device.networkSettings?.ipV4Address !== undefined &&
        device.networkSettings?.ipV4Address !== null
    )
    .map((device) => device.networkSettings?.ipV4Address as string);
  const takenIpV6s: string[] = Object.values(deviceList)
    .filter(
      (device) =>
        device.publicId !== targetDevicePublicId &&
        device.networkSettings?.ipV6Address !== undefined &&
        device.networkSettings?.ipV6Address !== null
    )
    .map((device) => device.networkSettings?.ipV6Address as string);

  // Validations that are shared between multiple fields
  const ipV4AddressValidation = useIpV4AddressValidationSchema(ipV4AddressTitle)
    .test('uniquePerSite', ipV4UniquePerSiteStr, (value: string) => {
      return !takenIpV4s.includes(value);
    })
    .required(ipV4RequiredStr);
  const subnetMaskValidation = useSubnetMaskValidationSchema(subnetMaskTitle);
  const ipV4DefaultGatewayValidation = useIpV4AddressValidationSchema(ipV4DefaultGatewayTitle);
  const ipV4PrimaryDnsValidation = useIpV4AddressValidationSchema(ipV4PrimaryDnsTitle);
  const ipV4SecondaryDnsValidation = useIpV4AddressValidationSchema(ipV4SecondaryDnsTitle);
  const ipV4MulticastAddressValidation = useIpV4AddressValidationSchema(ipV4MulticastAddressTitle);

  const ipV6AddressValidation = useIpV6AddressValidationSchema(ipV6AddressTitle)
    .test('uniquePerSite', ipV6UniquePerSiteStr, (value: string) => {
      return !takenIpV6s.includes(value);
    })
    .required(ipV4RequiredStr);
  const ipV6DefaultGatewayValidation = useIpV6AddressValidationSchema(ipV6DefaultGatewayTitle);
  const ipV6PrimaryDnsValidation = useIpV6AddressValidationSchema(ipV6PrimaryDnsTitle);
  const ipV6SecondaryDnsValidation = useIpV6AddressValidationSchema(ipV6SecondaryDnsTitle);
  const ipV6MulticastAddressValidation = useIpV6AddressValidationSchema(ipV6MulticastAddressTitle);
  const enumList: EnumList = fetchLocalEnumList();

  const getValidationSchema = (formDevice: IIPAddressFields, currValues: IIPAddressFields): Yup.ObjectSchema<any> => {
    const networkSchema: any = {};

    if (formDevice.networkSettings.hostName !== undefined) {
      const hostNameMaxLen = 64;
      networkSchema.hostName = Yup.string()
        .matches(RegexDomainName, hostNameErrorInvalid)
        .max(hostNameMaxLen, StringUtils.format(fieldErrorMaxLen, hostNameTitle, hostNameMaxLen));
    }

    if (formDevice.networkSettings.ipVersion !== undefined) {
      networkSchema.ipVersion = Yup.string()
        .required(ipVersionRequiredStr)
        .test('isValidMethod', ipVersionInvalidStr, (value: string) => {
          return Object.keys(enumList.ipVersion).includes(value);
        });
    }

    if (currValues.networkSettings?.ipVersion) {
      if (enumList.ipVersion[currValues.networkSettings?.ipVersion].value === 'IPV4') {
        if (formDevice.networkSettings.ipV4Address !== undefined) {
          networkSchema.ipV4Address = ipV4AddressValidation;
        }

        if (formDevice.networkSettings.subnetMask !== undefined) {
          networkSchema.subnetMask = Yup.string().when('ipV4Address', {
            is: (ipV4Address: string) => ipV4Address && ipV4Address.length > 0,
            then: () => subnetMaskValidation.required(subnetMaskErrorRequiredStr),
            otherwise: () => subnetMaskValidation
          });
        }

        if (formDevice.networkSettings.ipV4DefaultGateway !== undefined) {
          networkSchema.ipV4DefaultGateway = ipV4DefaultGatewayValidation;
        }

        if (formDevice.networkSettings.ipV4PrimaryDns !== undefined) {
          networkSchema.ipV4PrimaryDns = ipV4PrimaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV4SecondaryDns !== undefined) {
          networkSchema.ipV4SecondaryDns = ipV4SecondaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV4MulticastAddress !== undefined) {
          networkSchema.ipV4MulticastAddress = ipV4MulticastAddressValidation;
        }
      } else if (enumList.ipVersion[currValues.networkSettings?.ipVersion].value === 'IPV6') {
        if (formDevice.networkSettings.ipV6Address !== undefined) {
          networkSchema.ipV6Address = ipV6AddressValidation.required(ipV6RequiredStr);
        }

        if (formDevice.networkSettings.ipV6DefaultGateway !== undefined) {
          networkSchema.ipV6DefaultGateway = ipV6DefaultGatewayValidation;
        }

        if (formDevice.networkSettings.ipV6PrimaryDns !== undefined) {
          networkSchema.ipV6PrimaryDns = ipV6PrimaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV6SecondaryDns !== undefined) {
          networkSchema.ipV6SecondaryDns = ipV6SecondaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV6MulticastAddress !== undefined) {
          networkSchema.ipV6MulticastAddress = ipV6MulticastAddressValidation;
        }
      }
    }

    return Yup.object({
      networkSettings: Yup.object(networkSchema)
    });
  };

  return getValidationSchema;
};

export default useIPAddressValidationSchema;
