import {
  Cached,
  Circle,
  ExpandMoreOutlined,
  Search,
  CheckCircleOutline,
  InfoOutlined,
  ChevronRight,
  Memory,
  SettingsEthernetOutlined
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  chipClasses,
  Grid,
  lighten,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Typography
} from '@mui/material';
import { CustomMessageEvent } from 'context/SiteContext';
import React, { useContext, useEffect, useState } from 'react';
import { GatewayCommands, GatewayCommandStatus } from '../types/Types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import useSharedStyles from 'styles/useSharedStyles';
import { ActivityMessageType, RemoteManagementContext } from 'context/RemoteManagementContext';
import { ReadyState } from 'react-use-websocket';
import { Status } from 'shared/utils/Status';
import { SyncDevicePayload } from './DeviceDataGrid';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { setSelectedDevice } from 'store/slices/devicesSlice';
import SiteActivityErrorItem from './SiteActivityErrorItem';
import { useAppSelector } from 'store/hooks';
import SiteActivityErrorItemBatch from './SiteActivityErrorItemBatch';
import SiteActivityItemBatch from './SiteActivityItemBatch';
import { sortStationsByStatus } from './utils/utils';
import zIndex from '@mui/material/styles/zIndex';

export enum ActivityStatus {
  IN_PROGRESS = 'inProgress',
  SUCCESS = 'success',
  FAILURE = 'failure'
}

export interface ActivityItem {
  action: GatewayCommands;
  status: ActivityStatus;
  target: string;
  currentStep: number;
  totalSteps: number;
  icon?: JSX.Element;
  message: string;
  timeStamp: string;
  stations: {
    [key: string]: {
      statusCode: string;
    };
  };
  transactionId: string;
  type?: ActivityMessageType;
  command?: GatewayCommands;
  commandPayload?: any;
  needsAction?: boolean;
}

export const getPreSyncActions = (
  data: any,
  syncHandler: any,
  dispatch: Dispatch,
  navigate: NavigateFunction,
  t: any
) => {
  const needsUnitStr = t('RemoteManagement.SiteActivity.NeedsUnit');
  const needsUnitBtnStr = t('RemoteManagement.SiteActivity.NeedsUnitBtn');
  const uploadChangesStr = t('RemoteManagement.SiteActivity.UploadChanges');
  const needsAddressBookStr = t('RemoteManagement.SiteActivity.NeedsAddressBook');
  const needsAddressBookBtnStr = t('RemoteManagement.SiteActivity.NeedsAddressBookBtn');
  const needsCallListStr = t('RemoteManagement.SiteActivity.NeedsCallList');
  const needsCallListBtnStr = t('RemoteManagement.SiteActivity.NeedsCallListBtn');
  const needsAssociationStr = t('RemoteManagement.SiteActivity.NeedsAssociation');
  const needsAssociationBtnStr = t('RemoteManagement.SiteActivity.NeedsAssociationBtn');
  const retryStr = t('RemoteManagement.SiteActivity.Retry');

  const handleNavigateToAddressBook = () => {
    dispatch(setSelectedDevice(data.devicePublicId));
    navigate(`/site/${data.sitePublicId}/devices/${data.devicePublicId}/addressbook`);
  };

  const handleNavigateToCallList = () => {
    dispatch(setSelectedDevice(data.devicePublicId));
    navigate(`/site/${data.sitePublicId}/devices/${data.devicePublicId}/callsettings`);
  };

  const handleNavigateToNetworkInfo = () => {
    dispatch(setSelectedDevice(data.devicePublicId));
    navigate(`/site/${data.sitePublicId}/devices/${data.devicePublicId}/networkinfo`);
  };

  switch (data.deviceStatus) {
    case Status.NeedsSync:
      syncHandler(data.commandPayload, data.transactionId);
      break;
    case Status.NeedsUnit:
      return (
        <Box sx={styles.deviceStatusBox}>
          <Box>
            <Typography variant="body2" color="warning.main" sx={styles.flexCenterSmGap}>
              <InfoOutlined color="warning" />
              {needsUnitStr}
            </Typography>
          </Box>
          <Box sx={styles.deviceStatusDescription}>
            <Button>{needsUnitBtnStr}</Button>
            <Button
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={() => syncHandler(data.commandPayload, data.transactionId)}
            >
              {uploadChangesStr}
            </Button>
          </Box>
        </Box>
      );
    case Status.NeedsAddressBook:
      return (
        <Box sx={styles.deviceStatusBox}>
          <Box>
            <Typography variant="body2" color="warning.main" sx={styles.flexCenterSmGap}>
              <InfoOutlined color="warning" /> {needsAddressBookStr}
            </Typography>
          </Box>
          <Box sx={styles.deviceStatusDescription}>
            <Button onClick={handleNavigateToAddressBook}>{needsAddressBookBtnStr}</Button>
            <Button
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={() => syncHandler(data.commandPayload, data.transactionId)}
            >
              {uploadChangesStr}
            </Button>
          </Box>
        </Box>
      );
    case Status.NeedsCallList:
      return (
        <Box sx={styles.deviceStatusBox}>
          <Box>
            <Typography variant="body2" color="warning.main" sx={styles.flexCenterSmGap}>
              <InfoOutlined color="warning" />
              {needsCallListStr}
            </Typography>
          </Box>
          <Box sx={styles.deviceStatusDescription}>
            <Button onClick={handleNavigateToCallList}>{needsCallListBtnStr}</Button>
            <Button
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={() => syncHandler(data.commandPayload, data.transactionId)}
            >
              {uploadChangesStr}
            </Button>
          </Box>
        </Box>
      );
    case Status.NeedsAssociation:
      return (
        <Box sx={styles.deviceStatusBox}>
          <Box>
            <Typography variant="body2" color="error.main" sx={styles.flexCenterSmGap}>
              <InfoOutlined color="error" />
              {needsAssociationStr}
            </Typography>
          </Box>
          <Box sx={styles.deviceStatusDescription}>
            <Button startIcon={<Cached />} onClick={() => syncHandler(data.commandPayload, data.transactionId)}>
              {retryStr}
            </Button>

            <Button variant="outlined" onClick={handleNavigateToNetworkInfo} endIcon={<ChevronRight />}>
              {needsAssociationBtnStr}
            </Button>
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

export const createActivityItems = (
  messageHistory: CustomMessageEvent<any>[],
  t: TFunction,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction,
  syncCommand: (devicesToSync: Record<string, SyncDevicePayload>) => void,
  getStationNameFromIp: (ip: string) => string | undefined
) => {
  const activityMap = new Map<string, any>();
  const pleaseWaitStr = t('RemoteManagement.SiteActivity.InProgress');
  const searchPendingStr = t('RemoteManagement.SiteActivity.Search.Pending');
  const searchCompleteStr = t('RemoteManagement.SiteActivity.Search.Completed');
  const infoPendingStr = t('RemoteManagement.SiteActivity.Info.Pending');
  const infoCompleteOnlineStr = t('RemoteManagement.SiteActivity.Info.CompleteOnline');
  const infoCompleteOfflineStr = t('RemoteManagement.SiteActivity.Info.CompleteOffline');
  const syncCommandStr = t('RemoteManagement.SiteActivity.Sync.Command');
  const syncStartStr = t('RemoteManagement.SiteActivity.Sync.Started');
  const syncCompleteStr = t('RemoteManagement.SiteActivity.Sync.Completed');
  const registerStartStr = t('RemoteManagement.SiteActivity.Register.Started');
  const registerCompleteStr = t('RemoteManagement.SiteActivity.Register.Completed');
  const registerFailedStr = t('RemoteManagement.SiteActivity.Register.Failed');
  const invalidCredentialsStr = t('RemoteManagement.SiteActivity.InvalidCredentials');
  const cloudGatewayStr = t('Shared.CloudGateway');

  messageHistory.forEach((message: CustomMessageEvent<any>) => {
    const timeStamp = new Date(message.absoluteTimeStamp).toLocaleTimeString();
    const data = JSON.parse(message.data);
    const transactionId = data.transactionId;
    data.timeStamp = timeStamp;
    data.target = activityMap.get(transactionId)?.target || data.target || cloudGatewayStr;

    // Commands start at the client side and are sent to the server, so we need to check for the command in the message
    switch (data.command) {
      case GatewayCommands.SYNC:
        data.message = syncCommandStr;
        break;
    }

    // Actions are the server responses to the commands, so we need to check for the action in the message
    switch (data.action) {
      case GatewayCommands.SEARCH:
        if (data.currentStep < data.totalSteps) {
          data.message = searchPendingStr;
          data.status = ActivityStatus.IN_PROGRESS;
        } else {
          data.message = searchCompleteStr;
          data.status = ActivityStatus.SUCCESS;
        }
        data.icon = <Search />;
        break;
      case GatewayCommands.INFO:
        if (data.currentStep < data.totalSteps) {
          data.message = infoPendingStr;
          data.status = ActivityStatus.IN_PROGRESS;
        } else {
          if (data.status === GatewayCommandStatus.COMPLETED) {
            data.message = infoCompleteOnlineStr;
            data.status = ActivityStatus.SUCCESS;
          } else {
            if (data.errorCode) {
              switch (data.errorCode) {
                case '402':
                  data.message = invalidCredentialsStr;
                  break;
                default:
                  data.message = `Error: ${data.errorCode}`;
              }
            } else {
              data.message = infoCompleteOfflineStr;
            }
          }
        }
        data.icon = <Search />;
        break;
      case GatewayCommands.TEST:
        if (data.currentStep < data.totalSteps) {
          data.message = 'Test in progress';
          data.status = ActivityStatus.IN_PROGRESS;
        } else if (data.status === GatewayCommandStatus.COMPLETED) {
          data.message = 'Test complete';
          data.status = ActivityStatus.SUCCESS;
        }
        data.icon = <Search />;
        break;
      case GatewayCommands.SYNC:
      case GatewayCommands.CUSTOM_SOUND_SYNC:
        if (data.currentStep === 2 && data.status === GatewayCommandStatus.SUCCESS) {
          data.message = syncStartStr;
          data.status = ActivityStatus.IN_PROGRESS;
        }

        if (data.currentStep === 2 && data.status === GatewayCommandStatus.PENDING) {
          const successfulMessageRegex = /Device ([a-f0-9-]+) credential updated in RDS\./;
          const match = data.message.match(successfulMessageRegex);

          if (match) {
            const devicePublicId = match[1];
            data.message = `Device ${devicePublicId} has been synced`;
          } else {
            data.message = 'Syncing...';
          }

          data.stations = data.payload;
          data.status = ActivityStatus.IN_PROGRESS;
        }

        if (data.currentStep === 3 && data.status === GatewayCommandStatus.COMPLETED) {
          data.stations = data.payload;
          data.message = syncCompleteStr;
          data.status = ActivityStatus.SUCCESS;
        }

        if (data.status === GatewayCommandStatus.FAILED) {
          const match = data.message.match(/\[(.*?)\]/);
          const errorDetails = match ? match[1] : 'Unknown Error';

          data.message = `Sync failed: ${errorDetails}`;
          data.needsAction = true;
          data.status = ActivityStatus.FAILURE;
          data.errorCode = data.message.match(/:\s(\d{3}):/);
        }
        break;
      case GatewayCommands.REGISTER:
        if (data.currentStep < data.totalSteps) {
          data.message = registerStartStr;
          data.status = ActivityStatus.IN_PROGRESS;
        } else if (data.status === GatewayCommandStatus.SUCCESS) {
          data.message = registerCompleteStr;
          data.status = ActivityStatus.SUCCESS;
        } else if (data.status === GatewayCommandStatus.FAILED) {
          data.message = registerFailedStr;
          data.status = ActivityStatus.FAILURE;
        }
        data.icon = <CheckCircleOutline />;
        break;
      case GatewayCommands.FIRMWARE_UPDATE:
        if (data.currentStep < data.totalSteps) {
          data.message = 'Firmware update in progress';
          data.status = ActivityStatus.IN_PROGRESS;
        } else if (data.status === GatewayCommandStatus.COMPLETED) {
          data.message = 'Firmware update complete';
          data.status = ActivityStatus.SUCCESS;
        } else if (data.status === GatewayCommandStatus.FAILED) {
          data.message = 'Firmware update failed';
          data.status = ActivityStatus.FAILURE;
        }
        data.icon = <Memory />;
        break;
      case GatewayCommands.ASSOCIATE:
        if (data.currentStep < data.totalSteps) {
          data.message = 'Association in progress';
          data.status = ActivityStatus.IN_PROGRESS;
        } else if (data.status === GatewayCommandStatus.COMPLETED) {
          data.message = 'Association complete';
          data.status = ActivityStatus.SUCCESS;
        } else if (data.status === GatewayCommandStatus.FAILED) {
          data.message = 'Association failed';
          data.status = ActivityStatus.FAILURE;
        }
        data.icon = <SettingsEthernetOutlined />;
        break;
      default:
        break;
    }

    if (transactionId) {
      activityMap.set(transactionId, data);
    }
  });

  const activityItems = Array.from(activityMap.values()).map((item: ActivityItem) => {
    return (
      <Grid
        item
        xs={12}
        key={item.transactionId}
        sx={{
          width: '100%',
          paddingX: '12px',
          paddingY: '16px',
          borderBottom: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box sx={{ marginRight: '8px', color: 'primary.main' }}>{item.icon}</Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '8px' }}>
            <Typography variant="body1" color="text.primary">
              {item.message}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.timeStamp}
            </Typography>
          </Box>

          {item.target && (
            <Box>
              <Typography variant="body2" color="text.secondary">
                {item.target}
              </Typography>
            </Box>
          )}

          {item.needsAction && <SiteActivityErrorItem item={item} />}

          {item.type === ActivityMessageType.ACTION &&
            item.command === GatewayCommands.SYNC &&
            getPreSyncActions(item, syncCommand, dispatch, navigate, t)}

          {item.status === ActivityStatus.IN_PROGRESS && (
            <>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="indeterminate"
                  sx={{
                    height: 4,
                    borderRadius: 5,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: 'primary.main.light'
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: 'primary.main'
                    }
                  }}
                />
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {pleaseWaitStr}
                </Typography>
              </Box>
            </>
          )}

          {item.stations && (
            <Box>
              {Object.entries(item.stations)
                .sort(sortStationsByStatus)
                .map(([stationIp, stationData]) => {
                  const statusCode = stationData.statusCode.slice(0, 3);

                  switch (statusCode) {
                    case '200':
                      return <SiteActivityItemBatch stationName={getStationNameFromIp(stationIp)} />;
                    default:
                      return (
                        <SiteActivityErrorItemBatch
                          item={item}
                          statusCode={statusCode}
                          stationName={getStationNameFromIp(stationIp)}
                          stationIp={stationIp}
                        />
                      );
                  }
                })}
            </Box>
          )}
        </Box>
      </Grid>
    );
  });

  return activityItems.reverse();
};

const SiteActivityCommands = () => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const { messageHistory, activityItems, setActivityItems, readyState, syncCommand } =
    useContext(RemoteManagementContext);
  const { gatewayStatus } = useContext(RemoteManagementContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sharedStyle = useSharedStyles();
  const statusColor = sharedStyle.gatewayStatusColors[gatewayStatus];
  const deviceList = useAppSelector((state) => state.devices.DeviceList);

  const { t } = useTranslation();
  const siteActivityTitleStr = t('RemoteManagement.SiteActivity.Title');
  const gatewayStatusStr = t('RemoteManagement.SiteActivity.GatewayStatus', { status: gatewayStatus });
  const connectionClosedStr = t('RemoteManagement.SiteActivity.ConnectionClosed');
  const connectionClosedDescStr = t('RemoteManagement.SiteActivity.ConnectionClosedDesc');

  const getStationNameFromIp = (ip: string) => {
    const device = Object.values(deviceList).find((device) => device.networkSettings?.ipV4Address === ip);
    return device?.basicInfo.stationName;
  };

  useEffect(() => {
    if (messageHistory.length > 0) {
      setActivityItems(createActivityItems(messageHistory, t, dispatch, navigate, syncCommand, getStationNameFromIp));
      setIsAccordionExpanded(true);
    }
  }, [messageHistory]);

  return (
    <Paper elevation={6} sx={styles.activityCommandContainer}>
      <Accordion expanded={isAccordionExpanded} onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.activityCommandHeader}
        >
          <Box sx={styles.flexCenterSmGap}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {siteActivityTitleStr}
            </Typography>
          </Box>
          <Box>
            {readyState === ReadyState.CLOSED ? (
              <Box>
                <Chip
                  sx={{
                    backgroundColor: '#FFFFFF',
                    [`& .${chipClasses.icon}`]: {
                      color: 'divider'
                    },
                    fontSize: '14px'
                  }}
                  icon={
                    <Circle
                      sx={{
                        color: statusColor,
                        verticalAlign: 'middle',
                        fontSize: '18px',
                        border: `4px, solid, divider`,
                        borderRadius: '50%',
                        marginRight: '8px'
                      }}
                    />
                  }
                  label={connectionClosedStr}
                />
                <Typography variant="body2" color="text.secondary">
                  {connectionClosedDescStr}
                </Typography>
              </Box>
            ) : (
              <Chip
                sx={{
                  backgroundColor: '#FFFFFF',
                  [`& .${chipClasses.icon}`]: {
                    color: statusColor
                  },
                  color: statusColor,
                  fontSize: '14px'
                }}
                icon={
                  <Circle
                    sx={{
                      color: statusColor,
                      verticalAlign: 'middle',
                      fontSize: '18px',
                      border: `4px, solid, ${lighten(statusColor, 0.9)}`,
                      borderRadius: '50%',
                      marginRight: '8px'
                    }}
                  />
                }
                label={gatewayStatusStr}
              />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.activityItemContainer}>
          <Grid container>{activityItems}</Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

const styles = {
  activityCommandContainer: {
    width: '460px',
    borderRadius: '4px',
    position: 'absolute',
    right: '32px',
    bottom: '36px',
    zIndex: zIndex.drawer
  },
  activityCommandHeader: {
    backgroundColor: 'primary.light',
    flexDirection: 'row',
    borderBottom: '1px solid',
    borderColor: 'divider',
    height: '52px'
  },
  flexCenterSmGap: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  deviceStatusBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '8px'
  },
  deviceStatusDescription: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '8px',
    gap: '8px'
  },
  activityItemContainer: {
    padding: 0,
    overflowY: 'auto',
    maxHeight: '350px'
  }
};

export default SiteActivityCommands;
