import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import { ISiteInfo } from 'store/slices/siteSlice';
import useSharedStyles from 'styles/useSharedStyles';
import { IDevice } from 'store/slices/devicesSlice';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';

interface PageTitleProps {
  featureName: string;
  site?: ISiteInfo;
  device?: IDevice;
  icon: JSX.Element;
}

const getBaseUrl = (fullUrl: string): string => {
  return fullUrl.match(/^(.*\/site\/[a-f0-9-]+)/)?.[1] || fullUrl;
};

const getDeviceImageUrl = (device?: IDevice): string => {
  if (!device) return '';
  return (
    AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT +
    `/icons/${getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}.png`
  );
};

const PageBreadcrumbs = ({
  featureName,
  featureHref,
  siteName,
  deviceName,
  baseUrl,
  fullUrl,
  device
}: {
  featureName: string;
  featureHref: string;
  siteName?: string;
  deviceName?: string;
  baseUrl: string;
  fullUrl: string;
  device?: IDevice;
}) => (
  <Breadcrumbs aria-label="breadcrumb">
    <Link underline="hover" color="inherit" href={`/${featureHref}`}>
      {featureName}
    </Link>
    <Link underline="hover" color="inherit" href={device ? baseUrl : fullUrl}>
      {device ? siteName : <Typography color="text.primary">{siteName}</Typography>}
    </Link>
    {device && (
      <Link underline="hover" color="inherit" href={window.location.href}>
        <Typography color="text.primary">{deviceName}</Typography>
      </Link>
    )}
  </Breadcrumbs>
);

const PageTitle = ({ featureName, site, icon, device }: PageTitleProps) => {
  const sharedStyle = useSharedStyles();
  const siteName = site?.siteName;
  const deviceName = device?.basicInfo.stationName;
  const propertyId = site?.awsPropertyId;
  const featureHref = featureName.toLowerCase().replace(' ', '');
  const fullUrl = window.location.href;
  const baseUrl = getBaseUrl(fullUrl);
  const deviceImageUrl = getDeviceImageUrl(device);

  return (
    <Box sx={{ marginBottom: '1rem' }}>
      <Box sx={sharedStyle.centeredFlex}>
        <Box sx={sharedStyle.extraLargeIcon} color="primary.main">
          {deviceImageUrl ? <img src={deviceImageUrl} alt="Device Icon" /> : icon}
        </Box>
        <Box>
          <PageBreadcrumbs
            featureName={featureName}
            featureHref={featureHref}
            siteName={siteName}
            deviceName={deviceName}
            baseUrl={baseUrl}
            fullUrl={fullUrl}
            device={device}
          />
          <Typography variant="h5">{device ? deviceName : siteName}</Typography>
          <Typography variant="body2" color="text.secondary">
            {device ? device.basicInfo.macAddress : propertyId && `Property ID: ${propertyId}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PageTitle;
