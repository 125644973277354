import {
  AccountCircle,
  Apartment,
  ArrowBack,
  BusinessCenter,
  Dashboard,
  DeviceHub,
  Handyman,
  Help,
  OpenInNew,
  People,
  Receipt,
  Settings,
  SimCard,
  CreditCard
} from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useAppSelector } from 'store/hooks';
import { selectDevices } from 'store/slices/devicesSlice';
import { useTranslation } from 'react-i18next';
import { createDeviceMenuItems } from './deviceMenuItems';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IUserPermissions, getCurrentUser } from 'store/slices/usersSlice';
import { UserInfo } from 'shared/utils/UserUtils';

export interface SidebarMenuItem {
  icon: JSX.Element;
  text: string;
  to: string;
  target?: string;
  active?: boolean;
  type?: MenuItemEnum;
}

interface SubMenuItem {
  icon: JSX.Element;
  text: string;
  to: string;
  target?: string;
  active?: boolean;
  type?: MenuItemEnum;
  children: SidebarMenuItem[];
}

export enum MenuItemEnum {
  MenuItem = 'MenuItem',
  SubMenu = 'SubMenu'
}

/**
 * Custom hook to generate sidebar menu items based on user permissions and current location.
 *
 * This hook uses various permissions and feature access checks to dynamically create
 * sidebar menu items. It also provides translations for the menu items and determines
 * the active state based on the current location.
 *
 * @returns {Object} An object containing the following properties:
 * - `menuItems`: An array of sidebar menu items based on the current location.
 * - `footerMenuItems`: An array of footer menu items.
 * - `backMenuItem`: A back navigation menu item based on the current location.
 */
const useSidebarMenuItems = () => {
  const sitePublicId = useParams().id || '';
  const devicePublicId = useParams().deviceid || '';
  const unitPublicId = useParams().unitid || '';
  const deviceList = useAppSelector(selectDevices);
  const aclSiteId = useParams().publicId;
  const location = useLocation();
  const { isAllowedTo } = usePermission();

  const currentUser = useSelector(getCurrentUser);
  const userInfo = new UserInfo(currentUser);
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);

  const { t } = useTranslation();
  const dashboardStr = t('Sidebar.Dashboard');
  const adminToolsStr = t('Sidebar.AdminTools');
  const manageUsersStr = t('Sidebar.ManageUsers');
  const manageOrganizationsStr = t('Sidebar.ManageOrganizations');
  const quikSpecStr = t('Sidebar.QuikSpec');
  const simBillingStr = t('SimBilling.Title');
  const remoteManagementStr = t('RemoteManagement.Title');
  const acNioStr = t('acnio:AcNio');
  const siteDetailStr = t('Sidebar.SiteDetail');
  const siteAdministratorsStr = t('Sidebar.SiteAdministrators');
  const siteSettingsStr = t('Sidebar.SiteSettings');
  const siteInformationStr = t('Site.Information');
  const gatewayDevicesStr = t('Sidebar.GatewayStations');
  const billingStr = t('Sidebar.Billing');
  const unitInformationStr = t('Sidebar.UnitInformation');
  const QRAccessCodesStr = t('Sidebar.QRAccessCodes');
  const profileStr = t('Sidebar.Profile');
  const supportStr = t('Sidebar.Support');
  const feedbackStr = t('Sidebar.Feedback');
  const backToSimBillingStr = t('Sidebar.BackTo', { page: simBillingStr });
  const backToSiteStr = t('Sidebar.BackTo', { page: siteDetailStr });
  const backToRemoteManagementStr = t('Sidebar.BackTo', { page: remoteManagementStr });

  // Permissions and feature access checks
  const permissions: IUserPermissions | undefined = currentUser?.permissions;
  const registeredUser = currentUser ? !permissions || (!permissions.global.roleList && !permissions.branch) : true;
  const canAccessAllUserTabs =
    isAllowedTo('usersTab:view', null, PermissionsContextType.GLOBAL) &&
    isAllowedTo('organizationsTab:view', null, PermissionsContextType.GLOBAL);

  let canAccessBranchUsers = false;
  const branchPermissions = currentUser?.permissions.branch;

  if (branchPermissions) {
    const firstBranchKey = Object.keys(branchPermissions)[0];
    const firstBranchPublicId = branchPermissions[firstBranchKey]?.roleList?.[0]?.publicId || null;

    canAccessBranchUsers = isAllowedTo(
      'usersTab:view',
      currentBranch?.publicId || firstBranchPublicId,
      PermissionsContextType.BRANCH
    );
  }
  const canAccessOrganizationTab =
    isAllowedTo('company:view', null, PermissionsContextType.GLOBAL) ||
    isAllowedTo('branch:view', null, PermissionsContextType.GLOBAL);
  const canViewUsers = isAllowedTo('user:view', sitePublicId, PermissionsContextType.SITE);

  // Create menu items by adding active state based on the current location and children for submenus
  const createMenuItems = (items: SidebarMenuItem[] | SubMenuItem[]): (SidebarMenuItem | SubMenuItem)[] => {
    return items.map((item) => ({
      ...item,
      active: location.pathname === item.to || (item.to !== '/' && location.pathname.startsWith(item.to)),
      children: item.type === MenuItemEnum.SubMenu && 'children' in item ? createMenuItems(item.children) : []
    }));
  };

  // Default menu items to be rendered when no specific page is active
  const isPermissionDataReady = userInfo.isAiphoneAdmin()
    ? true
    : registeredUser
    ? true
    : currentBranch?.publicId ?? false;

  const defaultMenuItems = useMemo(() => {
    if (!isPermissionDataReady) return [];
    return createMenuItems(
      [
        { icon: <Dashboard />, text: dashboardStr, to: `/`, type: MenuItemEnum.MenuItem },
        (canAccessOrganizationTab || canAccessBranchUsers || canAccessAllUserTabs) && {
          icon: <Handyman />,
          text: adminToolsStr,
          to: `/users`,
          type: MenuItemEnum.SubMenu,
          children: [
            { icon: <People />, text: manageUsersStr, to: `/users`, type: MenuItemEnum.MenuItem },
            canAccessOrganizationTab && {
              icon: <Apartment />,
              text: manageOrganizationsStr,
              to: `/dealercompanies`,
              type: MenuItemEnum.MenuItem
            }
          ].filter(Boolean)
        },
        { icon: <Receipt />, text: quikSpecStr, to: `/quikspec`, type: MenuItemEnum.MenuItem },
        {
          icon: <SimCard />,
          text: simBillingStr,
          to: `/simbilling`,
          type: MenuItemEnum.MenuItem
        },
        {
          icon: <Apartment />,
          text: remoteManagementStr,
          to: `/remotemanagement`,
          type: MenuItemEnum.MenuItem
        },
        {
          icon: <CreditCard />,
          text: acNioStr,
          to: '/acniolicensing',
          type: MenuItemEnum.MenuItem
        }
      ].filter(Boolean) as SidebarMenuItem[]
    );
  }, [location.pathname, isPermissionDataReady]);

  // Menu items for SIM billing product
  const simBillingMenuItems = useMemo(
    () =>
      createMenuItems([
        {
          icon: <BusinessCenter />,
          text: siteInformationStr,
          to: `/simbilling/sites/${aclSiteId}/site`,
          type: MenuItemEnum.MenuItem
        },
        {
          icon: <DeviceHub />,
          text: gatewayDevicesStr,
          to: `/simbilling/sites/${aclSiteId}/gwdevices`,
          type: MenuItemEnum.MenuItem
        },
        {
          icon: <Receipt />,
          text: billingStr,
          to: `/simbilling/sites/${aclSiteId}/billing`,
          type: MenuItemEnum.MenuItem
        }
      ]),
    [location.pathname, sitePublicId]
  );

  // Menu items for remote management product
  const remoteManagementMenuItems = useMemo(
    () =>
      createMenuItems(
        [
          {
            icon: <Dashboard />,
            text: siteDetailStr,
            to: `/site/${sitePublicId}/dashboard`,
            type: MenuItemEnum.MenuItem
          },
          canViewUsers && {
            icon: <People />,
            text: siteAdministratorsStr,
            to: `/site/${sitePublicId}/users`,
            type: MenuItemEnum.MenuItem
          },
          {
            icon: <Settings />,
            text: siteSettingsStr,
            to: `/site/${sitePublicId}/settings`,
            type: MenuItemEnum.MenuItem
          }
        ].filter(Boolean) as SidebarMenuItem[]
      ),
    [location.pathname, sitePublicId]
  );

  // Menu items for units product, a sub-product of Remote Management
  const unitsMenuItems = useMemo(
    () =>
      createMenuItems([
        {
          icon: <Dashboard />,
          text: unitInformationStr,
          to: `/site/${sitePublicId}/units/${unitPublicId}/unitinformation`,
          type: MenuItemEnum.MenuItem
        },
        {
          icon: <People />,
          text: QRAccessCodesStr,
          to: `/site/${sitePublicId}/units/${unitPublicId}/qraccesscodes`,
          type: MenuItemEnum.MenuItem
        }
      ]),
    [location.pathname, sitePublicId]
  );

  // Menu items for advanced settings of a device, a sub-product of Remote Management
  const advancedSettingsMenuItems = createMenuItems(createDeviceMenuItems(deviceList[devicePublicId]));

  // Footer menu items
  const footerMenuItems = useMemo(
    () =>
      createMenuItems([
        { icon: <AccountCircle />, text: profileStr, to: '/profile', type: MenuItemEnum.MenuItem },
        { icon: <Help />, text: supportStr, to: '/support', type: MenuItemEnum.MenuItem },
        {
          icon: <OpenInNew />,
          text: feedbackStr,
          to: 'https://forms.office.com/r/sCQGkqNFxG',
          target: '_blank',
          type: MenuItemEnum.MenuItem
        }
      ]),
    [location.pathname]
  );

  // Determine which menu items to render based on the current location
  const menuItems = useMemo(() => {
    if (location.pathname.includes('/simbilling/sites')) {
      return simBillingMenuItems;
    } else if (location.pathname.includes('/units')) {
      return unitsMenuItems;
    } else if (location.pathname.includes('/devices')) {
      return advancedSettingsMenuItems;
    } else if (location.pathname.includes('/site')) {
      return remoteManagementMenuItems;
    } else {
      return defaultMenuItems;
    }
  }, [location.pathname, defaultMenuItems]);

  // Back navigation menu item
  const backMenuItem = useMemo(() => {
    if (location.pathname.includes('/simbilling/sites')) {
      return { icon: <ArrowBack />, text: backToSimBillingStr, to: `/simbilling` };
    } else if (location.pathname.includes('/units')) {
      return { icon: <ArrowBack />, text: backToSiteStr, to: `/site/${sitePublicId}/dashboard` };
    } else if (location.pathname.includes('/devices')) {
      return { icon: <ArrowBack />, text: backToSiteStr, to: `/site/${sitePublicId}/dashboard` };
    } else if (location.pathname.includes('/site')) {
      return { icon: <ArrowBack />, text: backToRemoteManagementStr, to: `/remoteManagement` };
    } else {
      return null;
    }
  }, [location.pathname, defaultMenuItems]);

  return { menuItems, footerMenuItems, backMenuItem };
};

export default useSidebarMenuItems;
