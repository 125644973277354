import { Alert, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';

const GatewayErrorBox: FC<{
  gatewayErrorMessage: string;
  gatewayErrorCode: string;
  existingId: string | undefined;
  existingPw: string | undefined;
  setExistingId: (existingId: string | undefined) => void;
  setExistingPw: (existingPw: string | undefined) => void;
  isLoading: boolean;
}> = ({ gatewayErrorMessage, gatewayErrorCode, existingId, existingPw, setExistingId, setExistingPw, isLoading }) => {
  const sharedStyle = useSharedStyles();
  const [unkownIdPw, setUnknownIdPw] = useState(false);
  const [_, setResetComplete] = useState(false);
  const { t } = useTranslation();
  const mismatchedCredentialsStr = t(
    'Site.Configure.RegisterGateway.FinishRegistration.MACAddress.MismatchedCredentials'
  );
  const gatewayIdStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.GatewayID');
  const gatewayPasswordStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.GatewayPassword');
  const resetGatewayTitleStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.ResetGateway.Title');
  const locateResetStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.ResetGateway.LocateReset');
  const holdResetStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.ResetGateway.HoldReset');
  const statusLEDStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.ResetGateway.StatusLED');
  const credentialsResetStr = t(
    'Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.ResetGateway.CredentialsReset'
  );
  const resetCompleteStr = t('Site.Configure.RegisterGateway.FinishRegistration.ErrorBox.ResetGateway.ResetComplete');

  return (
    <Alert variant="outlined" severity="error">
      <Typography variant="gatewayError">{gatewayErrorMessage}</Typography>
      {gatewayErrorCode === '420' && (
        <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.sm]}>
          <Typography variant="body1" color="error">
            {mismatchedCredentialsStr}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                value={unkownIdPw}
                onChange={(e) => {
                  setUnknownIdPw(e.target.checked);
                }}
              />
            }
            label={t('Gateway_ID_PW_Unknown')}
          />
          {unkownIdPw ? (
            <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.listBox, sharedStyle.common.gap.xs]}>
              <Typography variant="body1">{resetGatewayTitleStr}</Typography>
              <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
                <Grid
                  container
                  item
                  sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs, sharedStyle.listBox.list]}
                >
                  <Typography variant="listItem">{locateResetStr}</Typography>
                  <Typography variant="listItem">{holdResetStr}</Typography>
                  <Typography variant="listItem">{statusLEDStr}</Typography>
                  <Typography variant="listItem">{credentialsResetStr}</Typography>
                </Grid>
                <Button
                  onClick={() => {
                    setResetComplete(true);
                  }}
                  sx={sharedStyle.listBox.action}
                >
                  {resetCompleteStr}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <TextField
                id="existing-id"
                value={existingId}
                onChange={(e) => setExistingId(e.target.value)}
                label={gatewayIdStr}
                variant="outlined"
                fullWidth
                disabled={isLoading}
              />
              <TextField
                id="existing-pw"
                value={existingPw}
                onChange={(e) => setExistingPw(e.target.value)}
                label={gatewayPasswordStr}
                variant="outlined"
                fullWidth
                disabled={isLoading}
              />
            </>
          )}
        </Grid>
      )}
    </Alert>
  );
};

export default GatewayErrorBox;
