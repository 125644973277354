import { LoadingButton } from '@mui/lab';
import { Box, DialogActions, MenuItem, Select, TextField, Typography, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Cached } from '@mui/icons-material';
import useSharedStyles from 'styles/useSharedStyles';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { useTranslation } from 'react-i18next';
import {
  getModelNumberOptions,
  getStationTypeFromDeviceType,
  getValidStationTypeFromSearchType
} from 'shared/utils/helperFunctions';
import { RemoteManagementContext } from 'context/RemoteManagementContext';
import {
  useBatchUpdateAppsMutation,
  useBatchUpdateDevicesMutation,
  useCreateAppMutation,
  useCreateDeviceMutation,
  useCreateUnitsWizardMutation,
  useLazyGetAppWithSiteIdQuery,
  useLazyGetDeviceListWithSitePublicIdQuery,
  useLazyGetUnitListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { useParams } from 'react-router-dom';
import { getDeviceModelFromString } from 'shared/utils/helperFunctions';
import { useAppSelector } from 'store/hooks';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import useConfigureWizardCommUnits from 'features/RemoteManagement/Hooks/useConfigureWizardCommUnits';
import { DeviceUpdateHelper } from 'features/RemoteManagement/NewSiteWizard/utils/deviceUpdateHelpers';
import { DeviceList } from 'store/slices/devicesSlice';
import { IUnitList } from 'store/slices/unitsSlice';
import { VALID_STATION_NAME_REGEX, VALID_STATION_NUMBER_REGEX } from 'shared/constants/regex';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MAX_APPS } from 'features/RemoteManagement/types/Types';
import { DEFAULT_IP_ADDRESS } from 'shared/constants/ipaddresses';

interface Station {
  id: number;
  macAddress: string;
  stationType: string;
  stationModel: string;
  stationName: string;
  stationNumber: string;
  firmwareVersion: string;
  ipAddress: string;
  subnetMask: string;
  gateway: string;
  ipVersion?: number;
}

interface AddStationsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  allowAppConfig?: boolean;
  handleNextStep?: () => void;
}

const testIfValidStationName = (stationName: string): boolean => {
  return VALID_STATION_NAME_REGEX.test(stationName);
};

const testIfValidStationNumber = (stationNumber: string): boolean => {
  return VALID_STATION_NUMBER_REGEX.test(stationNumber);
};

const AddStationsModal = ({ isOpen, setIsOpen, handleNextStep, allowAppConfig }: AddStationsModalProps) => {
  const [rows, setRows] = useState<Station[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [formError, setFormError] = useState<string | null>(null);
  const { stationsFromSearch, searchCommand, isSearching, setErrorMessage, setSuccessMessage } =
    useContext(RemoteManagementContext);
  const sitePublicId = useParams().id;
  const [createDevices] = useCreateDeviceMutation();
  const [createUnits] = useCreateUnitsWizardMutation();
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const [updateDevices] = useBatchUpdateDevicesMutation();
  const [batchUpdateApps] = useBatchUpdateAppsMutation();
  const [fetchApps] = useLazyGetAppWithSiteIdQuery();
  const [createApps] = useCreateAppMutation();

  const { generateUnits } = useConfigureWizardCommUnits();
  const buildingPublicId = useAppSelector((state) => Object.keys(state.buildings.BuildingList)[0]);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const appGroups = useAppSelector((state) => state.apps.AppGroup);
  const appList = useAppSelector((state) => state.apps.AppList);
  const siteInfo = useAppSelector((state) => state.site.siteInfo);
  const users = useAppSelector((state) => state.users);
  const [unitList, setUnitList] = useState<IUnitList>({});
  const [showAppError, setShowAppError] = useState<boolean>(false);
  const enumList: EnumList = fetchLocalEnumList();
  const appCapableUnitTypes = Object.keys(enumList.unitType)
    .filter(
      (key) =>
        enumList.unitType[key].value === 'Residential' ||
        enumList.unitType[key].value === 'Commercial' ||
        enumList.unitType[key].value === 'Guard'
    )
    .map((key) => parseInt(key));
  const devicesThatRequireBuildings = Object.keys(enumList.deviceType).filter((key) => {
    return ['IXGW-GW', 'IXW-MA', 'IXGW-LC'].includes(enumList.deviceType[key].value);
  });
  const blackListedDeviceTypes = Object.keys(enumList.deviceType).filter((key) => {
    return ['IXGW-GW'].includes(enumList.deviceType[key].value);
  });

  const { t } = useTranslation();
  const configuringSiteStr = t('Site.Configure.Title');
  const addStationModalStr = t('RemoteManagement.Dashboard.AddStationsModal.Title');
  const searchButtonStr = t('RemoteManagement.Dashboard.AddStationsModal.SearchButton');
  const addStationsStr = t('RemoteManagement.Dashboard.StationGrid.ToolbarButtons.AddStations');
  const howManyAppsStr = t('Site.Configure.AddApps.HowManyApps');
  const upTo8Str = t('Site.Configure.AddApps.UpTo8');
  const numberOfAppsStr = t('Site.Configure.AddApps.NumberOfApps');
  const mobileAppStr = t('App.IXG_Mobile_App');
  const minApps = 0;
  const maxApps = MAX_APPS;
  const minAppsErrorStr = t('Field.Error.Min', { field: numberOfAppsStr, min: minApps });
  const maxAppsErrorStr = t('Field.Error.Max', { field: numberOfAppsStr, max: maxApps });
  const continueStr = t('Shared.Continue');
  const createAppStr = t('API.Action.CreateApp');
  const createAppErrorStr = t('API.Error.Generic', { action: createAppStr });

  const [configuringApps, setConfiguringApps] = useState(false);

  const sharedStyle = useSharedStyles();

  const rowsMapRef = useRef<Map<number, Station>>(new Map());

  useEffect(() => {
    rowsMapRef.current = new Map(rows.map((row) => [row.id, row]));
  }, [rows]);

  const processRowUpdate = (newRow: Station) => {
    const updatedMap = new Map(rowsMapRef.current);
    updatedMap.set(newRow.id, newRow);
    rowsMapRef.current = updatedMap;
    setRows(Array.from(updatedMap.values()));
    return newRow;
  };

  const handleStationSearch = () => {
    searchCommand();
  };

  const handleAddDevices = async () => {
    setIsAdding(true);
    const selectedDevices = rows.filter((row) => rowSelectionModel.includes(row.id));

    if (selectedDevices.length === 0) {
      setFormError(t('Station.Create.Error.NoSelection'));
      setIsAdding(false);
      return;
    }

    // Validate station name and station number
    const invalidDevices = selectedDevices.filter((device) => !device.stationName || !device.stationNumber);

    if (invalidDevices.length > 0) {
      setFormError(
        t('Station.Create.Error.MissingFields', {
          fields: invalidDevices
            .map((device) => device.macAddress || t('Station.Create.Error.UnknownDevice'))
            .join(', ')
        })
      );
      setIsAdding(false);
      return;
    }

    const createDevicesPayload = {
      sitePublicId: sitePublicId,
      devices: selectedDevices.map((device) => ({
        deviceType: Number(
          Object.keys(enumList.deviceType).find((key) => {
            return enumList.deviceType[key].value === device.stationType;
          })
        ),
        deviceModel: getDeviceModelFromString(device.stationModel),
        macAddress: device.macAddress,
        stationName: device.stationName,
        stationNumber: device.stationNumber,
        firmwareVersion: device.firmwareVersion,
        buildingPublicId: devicesThatRequireBuildings.includes(
          Object.keys(enumList.deviceType).find((key) => {
            return enumList.deviceType[key].value === device.stationType;
          }) || ''
        )
          ? buildingPublicId
          : undefined,
        advancedSettings: {
          networkSettings: {
            ipV4Address: device.ipAddress !== DEFAULT_IP_ADDRESS ? device.ipAddress : null,
            subnetMask: device.subnetMask,
            ipV4DefaultGateway: device.gateway !== device.ipAddress ? device.gateway : undefined,
            ipVersion: Number(device.ipVersion) === 0 ? undefined : Number(device.ipVersion)
          }
        }
      }))
    };

    await createDevices(createDevicesPayload)
      .unwrap()
      .then(async (response) => {
        let units;
        if (siteInfo.typeId === 1) {
          const newUnits = generateUnits(createDevicesPayload.devices, response);
          await createUnits(newUnits);
          units = await fetchUnits({
            sitePublicId,
            qty: -1,
            page: 0
          }).unwrap();
          setUnitList(units.unitList as IUnitList);
        }
        setSuccessMessage(t('Station.Create.Success'));
        const devices = await fetchDevices({ sitePublicId: sitePublicId, qty: -1, page: 0 }).unwrap();
        //TODO: Need a better way to check if this is the first time they are adding devices to the site
        if (Object.keys(devices.deviceList).length === 1) {
          await configureSystem(devices.deviceList, units.unitList);
        }

        if (allowAppConfig) {
          setConfiguringApps(true);
        } else if (handleNextStep) {
          handleNextStep();
        }
      })
      .catch((error) => {
        console.error('Error creating devices:', error);
        setErrorMessage(t('Station.Create.Error.Failed'));
      });

    setIsAdding(false);
  };

  const configureSystem = async (deviceList: DeviceList, unitList: IUnitList) => {
    if (siteInfo.typeId === 1) {
      const system = new DeviceUpdateHelper(
        deviceList,
        unitList,
        appGroups,
        appList,
        siteInfo.typeId,
        siteInfo.publicId
      );

      system.initialize(siteInfo, users.currentUser?.publicId || '');

      try {
        await system.configureAddressBook();
        await system.configureCallSettings();
        await system.configureDoorRelease();
        await system.configureAppAddressBook();

        await updateDevices(system.getPayload()).unwrap();

        await batchUpdateApps(system.getAppPayload()).unwrap();

        fetchDevices({ sitePublicId: sitePublicId, qty: -1, page: 0 });
      } catch (error: any) {
        console.error('Error configuring system:', error);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      headerName: 'MAC Address',
      field: 'macAddress',
      flex: 1,
      renderCell(params) {
        return (
          <Typography variant="body2" fontWeight={500}>
            {params.value}
          </Typography>
        );
      }
    },
    {
      headerName: 'Station Type',
      field: 'stationType',
      flex: 1,
      renderCell(params) {
        return <Typography>{getStationTypeFromDeviceType(params.value)}</Typography>;
      }
    },
    {
      headerName: 'Station Model',
      field: 'stationModel',
      type: 'singleSelect',
      flex: 1,
      renderCell(params) {
        const arrayOfOptions = getModelNumberOptions(params.row.stationType);
        if (!params.value) {
          params.value = arrayOfOptions[0];
          processRowUpdate({ ...params.row, stationModel: arrayOfOptions[0] });
        }
        return (
          <Select
            variant="filled"
            value={params.value}
            sx={{
              width: '100%',
              backgroundColor: 'inherit',
              paddingTop: 0,
              '&:hover': {
                backgroundColor: 'inherit'
              },
              '&:focus': {
                backgroundColor: 'inherit'
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              const updatedRow = { ...params.row, stationModel: event.target.value };
              processRowUpdate(updatedRow);
            }}
          >
            {arrayOfOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& img': {
                      marginRight: 1,
                      maxWidth: '24px'
                    }
                  }}
                >
                  <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${option}.png`} />
                  {option}
                </Box>
              </MenuItem>
            ))}
          </Select>
        );
      }
    },
    {
      headerName: 'Station Number',
      field: 'stationNumber',
      flex: 1,
      renderCell(params) {
        return (
          <TextField
            value={params.value}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              const updatedRow = { ...params.row, stationNumber: event.target.value };
              processRowUpdate(updatedRow);
              if (!testIfValidStationNumber(event.target.value)) {
                setFormError(
                  t('RemoteManagement.Dashboard.AddStationsModal.Errors.InvalidStationNumber', {
                    station: params.row.macAddress
                  })
                );
                return;
              } else {
                setFormError(null);
              }
            }}
            variant="standard"
            sx={{ paddingTop: '25px' }}
          />
        );
      }
    },
    {
      headerName: 'Station Name',
      field: 'stationName',
      flex: 1,
      renderCell(params) {
        return (
          <TextField
            value={params.value}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              const updatedRow = { ...params.row, stationName: event.target.value };
              processRowUpdate(updatedRow);
              if (!testIfValidStationName(event.target.value)) {
                setFormError(
                  t('RemoteManagement.Dashboard.AddStationsModal.Errors.InvalidStationName', {
                    station: params.row.macAddress
                  })
                );
                return;
              } else {
                setFormError(null);
              }
            }}
            onKeyDown={(e) => {
              // TODO: Come up with better solution to prevent the space key from scrolling the page
              if (e.key === ' ') {
                e.stopPropagation();
              }
            }}
            variant="standard"
            sx={{ paddingTop: '25px' }}
          />
        );
      }
    }
  ];

  useEffect(() => {
    const existingMacAddresses = Object.values(deviceList).map((device) => device.basicInfo.macAddress);
    let stationNumberStart = 90001;
    setRows(
      stationsFromSearch
        .filter((station) => {
          const currDeviceType =
            Object.keys(enumList.deviceType).find((key) => {
              return enumList.deviceType[key].value === station.device_type;
            }) || '';
          return (
            !blackListedDeviceTypes?.includes(currDeviceType) &&
            (!existingMacAddresses.includes(station.mac_addr) || !station.mac_addr)
          );
        })
        .map((station) => {
          const stationNumber = stationNumberStart.toString();
          const stationName = getStationTypeFromDeviceType(station.device_type);
          stationNumberStart++;

          return {
            id: station.mac_addr || crypto.randomUUID(), // Ensure a unique id
            macAddress: station.mac_addr,
            stationType: getValidStationTypeFromSearchType(station.device_type),
            stationName: station.station_name || `${stationName} ${stationNumber}`,
            stationNumber: station.station_number || stationNumber,
            stationModel: '',
            firmwareVersion: station.fw_ver,
            ipAddress: station.ip_addr,
            subnetMask: station.ip_subnet,
            gateway: station.ip_gateway,
            ipVersion: station.ip_ver ? Number(station.ip_ver) : undefined
          };
        })
    );
  }, [stationsFromSearch]);

  useEffect(() => {
    if (rowSelectionModel.length > 0) {
      setFormError(null);
    }
  }, [rowSelectionModel]);

  const addAppsValidation = yup.object().shape({
    numberOfApps: yup.number().required().min(minApps, minAppsErrorStr).max(maxApps, maxAppsErrorStr)
  });

  const handleAppCreation = async (values: { numberOfApps: number }) => {
    setIsAdding(true);
    setShowAppError(false);

    // if no eligable units create a commercial unit for the apps to be assigned to

    const unitListKeys = Object.keys(unitList);
    const appListKeys = Object.keys(appList);
    for (let unitWalker = 0; unitWalker < unitListKeys.length; unitWalker++) {
      if (appCapableUnitTypes.includes(unitList[unitListKeys[unitWalker]].unitType)) {
        const unitAppStationNumbers: string[] = [];
        appListKeys
          .filter((key) => appList[key].unitPublicId === unitListKeys[unitWalker])
          .forEach((key) => {
            unitAppStationNumbers.push(appList[key].stationNumber);
          });
        unitAppStationNumbers.sort();

        const startingStationNumber = unitList[unitListKeys[unitWalker]].unitNumber.padStart(10, '0');

        let numberAppend = 0;
        for (let appWalker = 0; appWalker < values.numberOfApps - unitAppStationNumbers.length; appWalker++) {
          let appStationNumber: string;

          do {
            ++numberAppend;
            appStationNumber = startingStationNumber + numberAppend.toString().padStart(2, '0');
          } while (appListKeys.find((key) => appList[key].stationNumber === appStationNumber));

          const newApp = {
            appData: {
              stationNumber: appStationNumber,
              stationName: `MobileApp${numberAppend}`,
              unitPublicId: unitListKeys[unitWalker]
            }
          };
          try {
            await createApps(newApp).unwrap();
          } catch (error) {
            setShowAppError(true);
            return;
          }
        }
      }
    }

    await fetchApps({
      sitePublicId: sitePublicId,
      qty: 1200, // enough for 150 units worth of apps
      page: 0
    });
    setIsAdding(false);
    if (handleNextStep) {
      handleNextStep();
    } else if (setIsOpen) {
      setIsOpen(false);
    }
  };

  return (
    <DialogWrapper
      header={configuringSiteStr}
      subheader="Adding Devices"
      setIsOpen={setIsOpen}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      maxWidth={configuringApps ? 'sm' : 'md'}
    >
      {configuringApps ? (
        <Formik initialValues={{ numberOfApps: 8 }} validationSchema={addAppsValidation} onSubmit={handleAppCreation}>
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <Grid container sx={sharedStyle.gridContainer}>
              <Typography variant="h6">{howManyAppsStr}</Typography>
              <Grid
                container
                item
                sx={[
                  sharedStyle.gridContainer,
                  sharedStyle.gridContainer.row,
                  sharedStyle.common.justifyContent.center
                ]}
              >
                <Box
                  component="img"
                  src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ixg-mobile-app.png`}
                  alt={mobileAppStr}
                />
                <Grid
                  container
                  item
                  sx={[
                    sharedStyle.gridContainer,
                    sharedStyle.common.gap.md,
                    sharedStyle.common.width.fit,
                    sharedStyle.common.justifyContent.center
                  ]}
                >
                  <Typography variant="body2">{upTo8Str}</Typography>
                  <TextField
                    type="number"
                    name="numberOfApps"
                    InputProps={{ inputProps: { min: minApps, max: maxApps } }}
                    value={values.numberOfApps}
                    onChange={handleChange}
                    helperText={touched.numberOfApps && (errors.numberOfApps || ' ')}
                    error={touched.numberOfApps && !!errors.numberOfApps}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <DialogActions sx={sharedStyle.common.padding.none}>
                <Grid
                  container
                  sx={[
                    sharedStyle.gridContainer,
                    sharedStyle.common.padding.none,
                    sharedStyle.common.gap.xs,
                    sharedStyle.common.width.fit,
                    {
                      alignItems: 'flex-end'
                    }
                  ]}
                >
                  <Typography variant="body2" color="error" sx={showAppError ? null : sharedStyle.common.hideItem}>
                    {createAppErrorStr}
                  </Typography>
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      loading={isAdding}
                      color="primary"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {continueStr}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Grid>
          )}
        </Formik>
      ) : (
        <>
          <Box>
            <Typography variant="h6">{addStationModalStr}</Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <LoadingButton startIcon={<Cached />} onClick={handleStationSearch} loading={isSearching}>
              {searchButtonStr}
            </LoadingButton>
          </Box>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              sx={{ border: 'none' }}
              showCellVerticalBorder={false}
              hideFooter
              hideFooterSelectedRowCount
              checkboxSelection
              rowHeight={80}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={(error) => {
                console.error('Error processing row update:', error);
              }}
              onRowSelectionModelChange={(newSelection) => {
                setRowSelectionModel(newSelection);
              }}
              rowSelectionModel={rowSelectionModel}
              loading={isSearching}
            />
          </Box>
          <DialogActions sx={sharedStyle.common.padding.none}>
            <Box sx={{ float: 'right' }}>
              <Grid
                container
                sx={[
                  sharedStyle.gridContainer,
                  sharedStyle.common.padding.none,
                  sharedStyle.common.gap.xs,
                  sharedStyle.gridContainer.action
                ]}
              >
                <Grid item>
                  <Typography variant="body2" color="error">
                    {formError && formError}
                  </Typography>
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    loading={isAdding}
                    color="primary"
                    onClick={() => {
                      if (formError) return;
                      handleAddDevices();
                    }}
                    sx={{ float: 'right', marginTop: 1, marginBottom: 1 }}
                  >
                    {addStationsStr}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </DialogActions>
        </>
      )}
    </DialogWrapper>
  );
};

export default AddStationsModal;
