import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { useState, useMemo, ChangeEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCreateUnitMutation, useLazyGetUnitListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { RootState } from 'store';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import useSharedStyles from 'styles/useSharedStyles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { VALID_STATION_NAME_REGEX } from 'shared/constants/regex';

interface IAddUnitDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextStep?: () => void;
}

const AddUnitDialog = ({ isOpen, setIsOpen, handleNextStep }: IAddUnitDialogProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const buildingId = Object.entries(buildingList).find(
    ([_key, building]) => building.buildingSequentialNumber === 1
  )?.[0];
  const sitePublicId = useParams().id;
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const [createUnit, { isLoading }] = useCreateUnitMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const sharedStyle = useSharedStyles();

  const { t } = useTranslation();
  const unitNumberStr = t('Unit.Number');
  const unitNameStr = t('Unit.Name');
  const unitStr = t('Unit.Unit', { count: 1 });
  const startingUnitNumber = t('Unit_StartingNumber');
  const endingUnitNumber = t('Unit_EndingNumber');
  const errorFailedToAdd = t('Error_FailedAdd');
  const fieldErrorMaxDigits = t('Field_Error_MaxDigits');
  const fieldErrorMinLen = t('Field.Error.MinLen');
  const fieldErrorMaxLen = t('Field.Error.MaxLen');
  const fieldErrorNumbersOnly = t('Field_Error_NumbersOnly');
  const sharedAddedSuccessfully = t('Shared_AddedSuccessfully');
  const sharedAdd = t('Shared.AddItem');
  const batchNumberInvalidOrderStr = t('Unit.Error.NumberInvalidOrder');
  const batchNumberConflictStr = t('Unit.Error.NumberConflict');
  const rangeConflictStr = t('Unit.Error.RangeConflict');
  const unitSiteMaxStr = t('Unit.SiteMax');
  const unitRangeStr = t('Unit.Range');
  const unitNameAppendedStr = t('Unit.NameAppended');
  const dialogTitleStr = t('Site.Configure.Title');
  const dialogSubtitleStr = t('Site.Configure.AddUnits.Title');
  const enumList: EnumList = fetchLocalEnumList();
  const typeOfUnitStr = t('Unit.TypeOf');
  const addUnit = StringUtils.format(sharedAdd, unitStr);
  const unitAddedSuccessfully = StringUtils.format(sharedAddedSuccessfully, unitStr);
  const failedToAddUnit = StringUtils.format(errorFailedToAdd, unitStr);
  const startingUnitNumberRequired = t('Field.Error.Required', { field: startingUnitNumber });
  const endingUnitNumberRequired = t('Field.Error.Required', { field: endingUnitNumber });
  const unitNumberNumbersOnly = StringUtils.format(fieldErrorNumbersOnly, unitNumberStr);

  const unitNameMinLen = 1;
  const unitNameMaxLen = 24;
  const unitNumberMaxDigits = 10;
  const siteMaxUnits = 9999;
  const unitNumberMaxDigitsStr = StringUtils.format(fieldErrorMaxDigits, unitNumberStr, unitNumberMaxDigits);
  const unitNumberRequired = t('Field.Error.Required', { field: unitNumberStr });
  const newUnitRangeStr = t('Unit.NewRange');
  const unitNameInvalidStr = t('Field.Error.Invalid', { field: unitNameStr });
  const unitNameMinLenStr = StringUtils.format(fieldErrorMinLen, unitNameMinLen);
  const unitNameMaxLenStr = StringUtils.format(fieldErrorMaxLen, unitNameMaxLen);

  interface unitTypeOption {
    id: number;
    name: string;
    desc: string;
  }

  const insideAreaTypdId = Object.keys(enumList.unitType).find(
    (key) => enumList.unitType[key].value === 'InsideArea'
  ) as string;
  const blackListedUnitTypeIds = [insideAreaTypdId];

  const unitTypeOptions = Object.keys(enumList.unitType)
    .filter((key) => !blackListedUnitTypeIds.includes(key))
    .map((key) => {
      return {
        id: parseInt(key),
        name: t('Unit.Types.' + enumList.unitType[key].value + '.Name'),
        desc: t('Unit.Types.' + enumList.unitType[key].value + '.Desc')
      };
    });

  const residentialTypeId = Object.keys(enumList.unitType).find(
    (key) => enumList.unitType[key].value === 'Residential'
  ) as string;
  const residentialType = unitTypeOptions.find(
    (option: unitTypeOption) => option.id === parseInt(residentialTypeId)
  ) as unitTypeOption;

  const addUnitGridStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.wrap.noWrap,
    sharedStyle.common.gap.sm,
    sharedStyle.common.width.fit
  ];
  const unitNumberGridStyle = [...addUnitGridStyle, sharedStyle.common.gap.md];
  const iconContainer = [
    sharedStyle.common.width.fit,
    sharedStyle.common.alignItems.center,
    {
      height: '2.5rem',
      display: 'flex',
      color: 'text.secondary'
    }
  ];
  const unitTypeSelectStyle = {
    width: '9rem'
  };
  const inputStyle = {
    maxWidth: '12.1875rem',
    height: '5.25rem'
  };

  const menuItemGridStyle = [
    sharedStyle.gridContainer,
    sharedStyle.common.gap.none,
    {
      width: '18rem',
      overflowX: 'hidden',
      whiteSpace: 'normal'
    }
  ];

  const newRangeBtnStyle = [
    sharedStyle.dialogContainer.fullWidthActionsContainer,
    sharedStyle.common.divider.top,
    sharedStyle.common.divider.bottom
  ];

  interface UnitRange {
    unitType: number;
    unitName: string;
    unitStartingNumber: string;
    unitEndingNumber: string;
  }

  const defaultRange: UnitRange = {
    unitType: residentialType.id,
    unitName: residentialType.name,
    unitStartingNumber: '',
    unitEndingNumber: ''
  };

  const initialValues: {
    ranges: UnitRange[];
  } = {
    ranges: [defaultRange]
  };

  const unitNumberValidation = useMemo(
    () =>
      yup
        .string()
        .matches(/^\d+$/, unitNumberNumbersOnly)
        .required(unitNumberRequired)
        .test('max', unitNumberMaxDigitsStr, (value) => value.length <= 10),
    [t]
  );

  const unitNameValidation = useMemo(
    () =>
      yup
        .string()
        .matches(VALID_STATION_NAME_REGEX, unitNameInvalidStr)
        .min(unitNameMinLen, unitNameMinLenStr)
        .max(unitNameMaxLen, unitNameMaxLenStr),
    [t]
  );

  const generateValidation = () => {
    return yup.object().shape({
      ranges: yup
        .array()
        .of(
          yup.object().shape({
            unitName: unitNameValidation,
            unitStartingNumber: unitNumberValidation
              .required(startingUnitNumberRequired)
              .test('range-order', batchNumberInvalidOrderStr, (value, context) => {
                const startNum = parseInt(value.toString(), 10);
                const endNum = parseInt(context.parent.unitEndingNumber);

                return startNum <= endNum;
              })
              .test('range-max', unitSiteMaxStr, (value, context) => {
                const startNum = parseInt(value.toString());
                const endNum = parseInt(context.parent.unitEndingNumber);

                return endNum - startNum <= siteMaxUnits - Object.keys(unitList).length;
              })
              .test('range-unique', batchNumberConflictStr, (value, context) => {
                const startNum = parseInt(value.toString());
                const endNum = parseInt(context.parent.unitEndingNumber);
                const buildingUnits = Object.values(unitList).filter((unit) => unit.buildingPublicId === buildingId);
                const reservedUnitNumbers = buildingUnits.map((unit) => unit.unitNumber);

                // If the range is too large don't bother checking for conflicts
                if (endNum - startNum > siteMaxUnits - Object.keys(unitList).length) {
                  return false;
                }

                for (let num = startNum; num <= endNum; num++) {
                  if (reservedUnitNumbers.includes(num.toString().padStart(3, '0'))) {
                    return false;
                  }
                }
                return true;
              }),
            unitEndingNumber: unitNumberValidation.required(endingUnitNumberRequired)
          })
        )
        .test('ranges-max', unitSiteMaxStr, (value) => {
          let totalUnits = 0;

          value?.forEach((range) => {
            // Only count the units if both the start and end numbers are provided
            if (range.unitStartingNumber && range.unitEndingNumber) {
              const startNum = parseInt(range.unitStartingNumber);
              const endNum = parseInt(range.unitEndingNumber);
              totalUnits += endNum - startNum;
            }
          });

          return totalUnits <= siteMaxUnits - Object.keys(unitList).length;
        })
        .test('range-numbers-overlap', rangeConflictStr, (value) => {
          if (!value) {
            return true;
          }

          // write for loop to make sure that the ranges do not overlap
          for (let i = 0; i < value.length; i++) {
            for (let j = i + 1; j < value.length; j++) {
              // If the ranges are the same, or if the start or end numbers are not provided, skip the check
              if (i == j || !value[i].unitStartingNumber || !value[j].unitStartingNumber) {
                continue;
              }

              const range1 = value[i];
              const range2 = value[j];
              const start1 = parseInt(range1.unitStartingNumber);
              const end1 = parseInt(range1.unitEndingNumber);
              const start2 = parseInt(range2.unitStartingNumber);
              const end2 = parseInt(range2.unitEndingNumber);

              if ((start1 <= start2 && start2 <= end1) || (start1 <= end2 && end2 <= end1)) {
                return false;
              }
            }
          }

          return true;
        })
    });
  };

  const validationSchema = generateValidation();

  const handleCloseDialog = () => {
    handleNextStep ? handleNextStep() : setIsOpen(false);
  };

  const handleSubmit = async (values: any) => {
    for (let rangeWalker = 0; rangeWalker < values.ranges.length; rangeWalker++) {
      const rangeValues: UnitRange = values.ranges[rangeWalker];
      for (let i = Number(rangeValues.unitStartingNumber); i <= Number(rangeValues.unitEndingNumber); i++) {
        const newUnitNumber = i.toString().padStart(3, '0');
        await createUnit({
          unitData: {
            // If the unit number has less than 3 digits, pad it with zeros
            unitNumber: newUnitNumber,
            unitName: rangeValues.unitName ? rangeValues.unitName + ' ' + newUnitNumber : newUnitNumber,
            unitType: rangeValues.unitType,
            buildingPublicId: buildingId
          }
        })
          .unwrap()
          .catch(() => {
            setErrorMessage(failedToAddUnit);
            setIsErrorAlertOpen(true);
          });
      }
    }

    fetchUnits({
      sitePublicId: sitePublicId ?? '',
      qty: -1,
      page: 0
    });
    handleCloseDialog();
  };

  return (
    <>
      <DialogWrapper
        header={dialogTitleStr}
        subheader={dialogSubtitleStr}
        open={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, values, touched, errors, setFieldValue }) => {
            const handleUnitTypeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
              const currVal = getIn(values, event.target.name);
              // get the range[index] from the event target name
              const currRange = event.target.name.split('.')[0];
              const unitNameRange = currRange + '.unitName';

              const oldUnitType: unitTypeOption = unitTypeOptions.find(
                (option: unitTypeOption) => option.id === currVal
              ) as unitTypeOption;
              const newUnitType: unitTypeOption = unitTypeOptions.find(
                (option: unitTypeOption) => option.id === parseInt(event.target.value)
              ) as unitTypeOption;

              if (getIn(values, unitNameRange) === oldUnitType.name) {
                setFieldValue(unitNameRange, newUnitType.name);
              }

              handleChange(event);
            };

            const handleUnitNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              const unitNumber = parseInt(event.target.value);
              if (unitNumber >= 0) {
                handleChange(event);
              }
            };

            return (
              <Form>
                <FieldArray
                  name="ranges"
                  render={(arrayHelpers) => (
                    <Grid container sx={sharedStyle.gridContainer}>
                      <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
                        <Typography variant="h6">{unitRangeStr}</Typography>
                        <Typography variant="body1">{unitNameAppendedStr}</Typography>
                      </Grid>
                      <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xxs]}>
                        {values.ranges.map((range: UnitRange, index: number) => (
                          <Grid container sx={addUnitGridStyle} key={`range-${index}`}>
                            <Grid item>
                              <TextField
                                size="small"
                                name={`ranges[${index}].unitType`}
                                id={`ranges-${index}-unitType`}
                                label={typeOfUnitStr}
                                value={getIn(values, `ranges[${index}].unitType`)}
                                select
                                sx={unitTypeSelectStyle}
                                onBlur={handleBlur}
                                onChange={handleUnitTypeChange}
                                SelectProps={{
                                  renderValue: (value) => {
                                    const unitType = unitTypeOptions.find(
                                      (option) => option.id === (value as number)
                                    ) as unitTypeOption;
                                    return <Typography>{unitType.name}</Typography>;
                                  }
                                }}
                                error={
                                  getIn(touched, `ranges[${index}].unitType`) &&
                                  !!getIn(errors, `ranges[${index}].unitType`)
                                }
                                helperText={
                                  (getIn(touched, `ranges[${index}].unitType`) &&
                                    getIn(errors, `ranges[${index}].unitType`)) ||
                                  ' '
                                }
                              >
                                {unitTypeOptions.map((typeOption: unitTypeOption, index: number) => (
                                  <MenuItem key={index} value={typeOption.id}>
                                    <Grid container sx={menuItemGridStyle}>
                                      <Typography variant="body1">{typeOption.name}</Typography>
                                      <Typography variant="body2" color="textSecondary">
                                        {typeOption.desc}
                                      </Typography>
                                    </Grid>
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item>
                              <TextField
                                size="small"
                                name={`ranges[${index}].unitName`}
                                id={`ranges-${index}-unitName`}
                                label={unitNameStr}
                                placeholder={unitNameStr}
                                value={getIn(values, `ranges[${index}].unitName`)}
                                sx={inputStyle}
                                onChange={handleChange}
                                error={
                                  getIn(touched, `ranges[${index}].unitName`) &&
                                  !!getIn(errors, `ranges[${index}].unitName`)
                                }
                                helperText={
                                  (getIn(touched, `ranges[${index}].unitName`) &&
                                    getIn(errors, `ranges[${index}].unitName`)) ||
                                  ' '
                                }
                              />
                            </Grid>
                            <Grid container item sx={unitNumberGridStyle}>
                              <TextField
                                type="number"
                                size="small"
                                name={`ranges[${index}].unitStartingNumber`}
                                id={`ranges-${index}-unitStartingNumber`}
                                label={startingUnitNumber}
                                placeholder={startingUnitNumber}
                                value={getIn(values, `ranges[${index}].unitStartingNumber`)}
                                sx={inputStyle}
                                onChange={handleUnitNumberChange}
                                error={
                                  getIn(touched, `ranges[${index}].unitStartingNumber`) &&
                                  !!getIn(errors, `ranges[${index}].unitStartingNumber`)
                                }
                                helperText={
                                  (getIn(touched, `ranges[${index}].unitStartingNumber`) &&
                                    getIn(errors, `ranges[${index}].unitStartingNumber`)) ||
                                  ' '
                                }
                              />
                              <Box sx={iconContainer}>
                                <ArrowForwardIcon />
                              </Box>
                              <TextField
                                type="number"
                                size="small"
                                name={`ranges[${index}].unitEndingNumber`}
                                id={`ranges-${index}-unitEndingNumber`}
                                label={endingUnitNumber}
                                placeholder={endingUnitNumber}
                                value={getIn(values, `ranges[${index}].unitEndingNumber`)}
                                sx={inputStyle}
                                onChange={handleUnitNumberChange}
                                error={
                                  getIn(touched, `ranges[${index}].unitEndingNumber`) &&
                                  !!getIn(errors, `ranges[${index}].unitEndingNumber`)
                                }
                                helperText={
                                  (getIn(touched, `ranges[${index}].unitEndingNumber`) &&
                                    getIn(errors, `ranges[${index}].unitEndingNumber`)) ||
                                  ' '
                                }
                              />
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                                disabled={values.ranges.length === 1}
                                sx={values.ranges.length === 1 ? sharedStyle.common.hideItem : {}}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item sx={newRangeBtnStyle}>
                          <Button variant="text" size="large" onClick={() => arrayHelpers.push(defaultRange)}>
                            <Box sx={sharedStyle.inlineBox}>
                              <AddIcon sx={sharedStyle.inlineBox.buttonIcon} />
                              {newUnitRangeStr}
                            </Box>
                          </Button>
                        </Grid>
                        <DialogActions sx={sharedStyle.common.padding.none}>
                          <Grid
                            container
                            sx={[
                              sharedStyle.gridContainer,
                              sharedStyle.common.padding.none,
                              sharedStyle.common.gap.xs,
                              sharedStyle.gridContainer.action
                            ]}
                          >
                            <Grid item>
                              <Typography sx={sharedStyle.errorMessage.fixedHeight} variant="error">
                                {typeof errors.ranges === 'string' ? errors.ranges : ' '}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <LoadingButton variant="contained" type="submit" loading={isLoading}>
                                {addUnit}
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogWrapper>
      <SnackbarAlert
        type="success"
        time={3000}
        text={unitAddedSuccessfully}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

export default AddUnitDialog;
