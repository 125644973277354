import { Alert, Box, Button, lighten, Tooltip, Typography } from '@mui/material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import useSharedStyles from 'styles/useSharedStyles';
import CircleIcon from '@mui/icons-material/Circle';
import ActionCenterButton from './ActionCenterButton';
import { Search, Memory, RestartAlt, SimCardOutlined, Smartphone, DoneAll } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoteManagementContext } from 'context/RemoteManagementContext';
import { useAppSelector } from 'store/hooks';
import {
  generateFirmwareUpdatePayload,
  getLatestFirmwareVersions,
  getSetUsernameAndToken,
  isLatestFirmware
} from './utils/utils';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { useNavigate } from 'react-router-dom';
import { useAclAuth } from 'features/SimBilling/Hooks';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import LoginPanel from './Dialogs/LoginPanel';
import { useDispatch } from 'react-redux';
import CONFIG from '../../../config';
import { fetchLocalEnumList, IDeviceModelValue } from 'shared/utils/EnumUtils';

interface GatewayActionCenterProps {
  latestFirmwareList: any;
}

const GatewayActionCenter = ({ latestFirmwareList }: GatewayActionCenterProps) => {
  const [isLoginPanelOpen, setIsLoginPanelOpen] = useState(false);
  const {
    infoCommand,
    rebootCommand,
    updateFirmwareCommand,
    testCommand,
    isSyncingWithAcl,
    setIsSyncingWithAcl,
    setSuccessMessage,
    gatewayStatus,
    gatewayMacAddress,
    gatewayPublicId,
    gatewayFirmwareVersion
  } = useContext(RemoteManagementContext);
  const { aclToken } = useAclAuth();
  const sharedStyle = useSharedStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setUsernameAndToken = getSetUsernameAndToken(setIsLoginPanelOpen, dispatch);

  const propertyId = useAppSelector((state) => state.site.siteInfo.awsPropertyId);
  const sitePublicId = useAppSelector((state) => state.site.siteInfo.publicId);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const callingUserPublicId = localStorage.getItem('userId') ?? '';
  const statusColor = sharedStyle.gatewayStatusColors[gatewayStatus];
  const hasCheckedStatus = useRef(false);
  const gwDevice = gatewayPublicId ? deviceList[gatewayPublicId] : undefined;
  const enumList = fetchLocalEnumList();
  const tgwDeviceModel: number = (
    enumList.deviceModel[
      Object.keys(enumList.deviceModel).find((key) => {
        return enumList.deviceModel[key].value === 'IXGW-TGW';
      }) as string
    ] as IDeviceModelValue
  ).modelNumber;
  const gatewayModel = gwDevice?.basicInfo.deviceModel !== tgwDeviceModel ? 'IXGW-GW' : 'IXGW-TGW';
  const ixgtgwImageUrl = AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT + `/${gatewayModel}.svg`;
  const unitList = useAppSelector((state) => state.units.UnitList);
  const appList = useAppSelector((state) => state.apps.AppList);

  const unitIds: string[] = [];
  const roomIds: number[] = [];
  Object.keys(unitList).forEach((unitId) => {
    const unit = unitList[unitId];
    if (unit.appPublicIds !== null) {
      unitIds.push(unit.publicId);
      roomIds.push(unit.unitSequentialNumber);
    }
  });

  const { t } = useTranslation();
  const checkStatusStr = t('RemoteManagement.Dashboard.GatewayActions.CheckStatus');
  const rebootGatewayStr = t('RemoteManagement.Dashboard.GatewayActions.RebootGateway');
  const updateFirmwareStr = t('RemoteManagement.Dashboard.GatewayActions.UpdateFirmware.Title');
  const updateFirmwareTooltipStr = t('RemoteManagement.Dashboard.GatewayActions.UpdateFirmware.Tooltip');
  const updateFirmwareTooltipDisabledStr = t(
    'RemoteManagement.Dashboard.GatewayActions.UpdateFirmware.TooltipDisabled'
  );
  const saveMobileAppSettingsStr = t('RemoteManagement.Dashboard.GatewayActions.SaveMobile');
  const manageSimStr = t('RemoteManagement.Dashboard.GatewayActions.ManageSim');
  const loginPanelTitle = t('IXGLogin.LoginPanel.ManageSitesTitle');
  const loginStr = t('RemoteManagement.Dashboard.GatewayActions.LoginIXGButton');
  const loginDescStr = t('RemoteManagement.Dashboard.GatewayActions.LoginDesc');
  //const simDataStr = t('RemoteManagement.Dashboard.SimData');

  const handleCheckStatus = () => {
    if (gatewayFirmwareVersion && Number(gatewayFirmwareVersion) >= 4.82) {
      infoCommand();
    } else {
      testCommand();
    }
  };

  const handleRebootGateway = () => {
    rebootCommand();
  };

  const handleUpdateFirmware = async () => {
    if (!gatewayPublicId) return;

    const payload = await generateFirmwareUpdatePayload(gatewayPublicId, deviceList, latestFirmwareList);
    updateFirmwareCommand(payload);
  };

  const checkFirmwareVersion = () => {
    if (!gatewayPublicId) return;

    const gatewayDevice = deviceList[gatewayPublicId];
    if (!gatewayDevice || !gatewayDevice.basicInfo.firmwareVersion) return false;

    const modelNumber =
      getDeviceModelNumberFromModelType(gatewayDevice.basicInfo.deviceModel, gatewayDevice.basicInfo.deviceType) || '';
    const { latestStandardFirmware, latestEnhancedFirmware } = getLatestFirmwareVersions(
      modelNumber,
      latestFirmwareList
    );
    const currentFirmware = Number(gatewayDevice.basicInfo.firmwareVersion.replace('.', ''));
    return isLatestFirmware(currentFirmware, latestStandardFirmware, latestEnhancedFirmware);
  };

  const handleSyncWithAcl = async () => {
    if (!gatewayPublicId) {
      return;
    }

    try {
      setIsSyncingWithAcl(true);

      const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'upload',
          propertyId: propertyId,
          token: aclToken,
          siteId: sitePublicId,
          userId: callingUserPublicId
        })
      });
      if (sync_result.status !== 200) {
        throw new Error('Failed to sync with IXG Cloud');
      }

      await new Promise((resolve) => setTimeout(resolve, 5000));

      const confirm_results = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'getIntercomAccessDate',
          propertyId: propertyId,
          token: aclToken,
          clientId: deviceList[gatewayPublicId].gatewaySettings?.gatewaySerialNumber,
          userId: callingUserPublicId
        })
      });

      if (confirm_results.status !== 200) {
        const errorMessages: { [key: string]: string } = {
          401: t('Gateway_Error_message.RM-GW-100'),
          500: t('Gateway_Error_message.RM-GW-101')
        };

        throw new Error(errorMessages[confirm_results.status] || t('Gateway_Error_message.RM-GW-199'));
      } else {
        setSuccessMessage('Synced with IXG Cloud');
      }
      setIsSyncingWithAcl(false);

      // wait 1 minute
      await new Promise((resolve) => setTimeout(resolve, 30000));

      const qrResult = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'authcode',
          propertyId: propertyId,
          token: aclToken,
          siteId: sitePublicId,
          userId: callingUserPublicId,
          unitIds: unitIds,
          roomIds: roomIds
        })
      });

      const message = await qrResult.body
        ?.getReader()
        .read()
        .then(({ value }) => new TextDecoder().decode(value));

      let success = true;
      let appUnitIds = [];
      let appIds = [];
      for (const app in appList) {
        appUnitIds.push(appList[app].unitPublicId);
        appIds.push(app);

        // TODO make proper batch handling for app sync.
        if (appIds.length === 30) {
          const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              action: 'setqrcodes',
              userId: callingUserPublicId,
              unitIds: appUnitIds,
              appIds
            })
          });

          appUnitIds = [];
          appIds = [];

          if (sync_result.status !== 200) {
            success = false;
            break;
          }
        }
      }

      if (appIds.length > 0 && success === true) {
        const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'setqrcodes',
            userId: callingUserPublicId,
            unitIds: appUnitIds,
            appIds
          })
        });

        if (sync_result.status !== 200) {
          success = false;
        }
      }

      if (message === 'Success' && success === true) {
        // show success message
        //setShowIXGSync(true);
      }
      //setIsSyncDialogOpen(false);
    } catch (error) {
      //setErrorMessage(t('Failed_to_sync_with_IXG_Cloud') + (error as Error).message);
      //setIsSyncDialogOpen(false);
    }
  };

  const handleNavigateToSim = () => {
    navigate(`/simbilling/sites/${propertyId}/gwdevices`);
  };

  const handleClose = () => {
    setIsLoginPanelOpen(false);
  };

  useEffect(() => {
    if (
      !hasCheckedStatus.current &&
      gatewayMacAddress &&
      gatewayFirmwareVersion &&
      Number(gatewayFirmwareVersion) >= 4.82
    ) {
      infoCommand();
      hasCheckedStatus.current = true;
    }
  }, [infoCommand, gatewayMacAddress]);

  return (
    <>
      <Box sx={[sharedStyle.infoBox, sharedStyle.common.width.full, styles.gatewayActionCenterContainer]}>
        <Box sx={{ width: '10rem' }}>
          <Box>
            <img width={'90%'} src={ixgtgwImageUrl} alt="Gateway Action Center" />
          </Box>
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <Typography variant="body2" color={statusColor}>
              <CircleIcon
                sx={{
                  color: statusColor,
                  verticalAlign: 'middle',
                  fontSize: '18px',
                  border: `4px, solid, ${lighten(statusColor, 0.9)}`,
                  borderRadius: '50%',
                  marginRight: '8px'
                }}
              />
              {gatewayStatus}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: '64px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '28px',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <ActionCenterButton icon={<Search />} text={checkStatusStr} handler={handleCheckStatus} />
            <ActionCenterButton
              icon={<RestartAlt />}
              text={rebootGatewayStr}
              gatewayStatus={gatewayStatus}
              handler={handleRebootGateway}
              disabled={!gatewayFirmwareVersion}
            />
            <Tooltip title={checkFirmwareVersion() ? updateFirmwareTooltipDisabledStr : updateFirmwareTooltipStr}>
              <span>
                <ActionCenterButton
                  icon={<Memory />}
                  text={updateFirmwareStr}
                  gatewayStatus={gatewayStatus}
                  isFirmwareLatest={checkFirmwareVersion()}
                  handler={handleUpdateFirmware}
                />
              </span>
            </Tooltip>

            <ActionCenterButton
              icon={<Smartphone />}
              text={saveMobileAppSettingsStr}
              gatewayStatus={gatewayStatus}
              handler={handleSyncWithAcl}
              disabled={!aclToken || !gatewayFirmwareVersion || isSyncingWithAcl}
            />
            <ActionCenterButton icon={<SimCardOutlined />} text={manageSimStr} handler={handleNavigateToSim} />
          </Box>
          {!aclToken && (
            <Box>
              <Alert
                icon={<DoneAll />}
                severity="info"
                action={
                  <Button onClick={() => setIsLoginPanelOpen(true)}>
                    <Typography variant="body2">{loginStr}</Typography>
                  </Button>
                }
              >
                {loginDescStr}
              </Alert>
            </Box>
          )}

          {/* <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '4px'
            }}
          >
            <Box>
              <Typography variant="body2" color="text.secondary">
                {simDataStr}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                {1} / {2} {'GB'}
              </Typography>
            </Box>
          </Box>
          <LinearProgress
            variant="determinate"
            value={50}
            sx={{
              height: 10,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: 'primary.main.light'
              },
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: 'primary.main'
              }
            }}
          />
        </Box> */}
        </Box>
      </Box>
      <DialogWrapper
        header={loginPanelTitle}
        setIsOpen={setIsLoginPanelOpen}
        open={isLoginPanelOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <LoginPanel setUsernameAndToken={setUsernameAndToken} setIsLoginPanelOpen={setIsLoginPanelOpen} />
      </DialogWrapper>
    </>
  );
};

const styles = {
  gatewayActionCenterContainer: { marginY: 4, paddingX: '36px', paddingY: '32px', display: 'flex' }
};

export default GatewayActionCenter;
