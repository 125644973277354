import { UUID } from 'crypto';
import { ISound } from 'features/RemoteManagement/types/Types';
import { IChimeSchedule, IDevice } from 'store/slices/devicesSlice';

const isSoundCustom = (sound: ISound): boolean => {
  return sound?.s3_location?.includes('https://sounds.aiphone.cloud/custom/') || false;
};

const tryAddCustomSound = (soundPublicId: UUID | null | undefined, customSounds: ISound[], siteSoundList: ISound[]) => {
  const sound = soundPublicId ? siteSoundList.find((s) => s.public_id === soundPublicId) : undefined;
  if (sound && isSoundCustom(sound) && !customSounds.some((sound) => sound.public_id === soundPublicId)) {
    customSounds.push(sound);
  }
};

export const getDeviceCustomSounds = (device: IDevice, siteSoundList: ISound[]): ISound[] => {
  const customSounds: ISound[] = [];

  // contact input sounds
  device.contactInputList?.forEach((contactInput) => {
    tryAddCustomSound(contactInput.ringtone, customSounds, siteSoundList);
    tryAddCustomSound(contactInput.pagingGroup?.soundSettingPublicId, customSounds, siteSoundList);
  });

  // contact output sounds
  device.contactOutputList?.forEach((contactOutput) => {
    tryAddCustomSound(contactOutput.sound, customSounds, siteSoundList);
    tryAddCustomSound(contactOutput.soundStart, customSounds, siteSoundList);
    tryAddCustomSound(contactOutput.soundEnd, customSounds, siteSoundList);
  });

  // device setting sounds
  tryAddCustomSound(device.deviceSettings?.timeoutSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.timeoutWarningSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.incomingCallSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.pagingSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.autoAnswerSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.onHoldSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.doorReleaseSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.errorSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.communicationStartSound, customSounds, siteSoundList);
  tryAddCustomSound(device.deviceSettings?.monitoringStartSound, customSounds, siteSoundList);

  device.deviceSettings?.deviceSpeedDial?.forEach((speedDial) => {
    tryAddCustomSound(speedDial.groupMessagePageCustomSoundPublicId, customSounds, siteSoundList);
    tryAddCustomSound(speedDial.allMessagePageCustomSoundPublicId, customSounds, siteSoundList);
  });

  // function settings sounds
  tryAddCustomSound(device.functionSettings?.pagingPretoneSound, customSounds, siteSoundList);
  tryAddCustomSound(device.functionSettings?.callStartSound, customSounds, siteSoundList);
  tryAddCustomSound(device.functionSettings?.callStartMessageList?.messageSound, customSounds, siteSoundList);
  Object.values(device.functionSettings?.chimeSchedule || {}).forEach((chimeDay: IChimeSchedule[]) => {
    chimeDay.forEach((schedule) => {
      tryAddCustomSound(schedule.soundType, customSounds, siteSoundList);
      tryAddCustomSound(schedule.customSoundPublicId, customSounds, siteSoundList);
    });
  });

  // call settings sounds
  tryAddCustomSound(device.callSettings?.ringtoneSetting, customSounds, siteSoundList);
  tryAddCustomSound(device.callSettings?.incomingCallRingtone, customSounds, siteSoundList);
  tryAddCustomSound(device.callSettings?.callBusyToneSettings, customSounds, siteSoundList);
  tryAddCustomSound(device.callSettings?.callErrorTone, customSounds, siteSoundList);

  // entrance panel setting sounds
  tryAddCustomSound(device.entrancePanelSettings?.shockSensorWarningSound, customSounds, siteSoundList);

  // system info sounds
  device.systemInfo?.addressBook?.forEach((addressBook) => {
    tryAddCustomSound(addressBook.ringtoneSetting?.callButtonNormalSound, customSounds, siteSoundList);
    tryAddCustomSound(addressBook.ringtoneSetting?.callButtonPrioritySound, customSounds, siteSoundList);
    tryAddCustomSound(addressBook.ringtoneSetting?.callButtonUrgentSound, customSounds, siteSoundList);
    tryAddCustomSound(addressBook.ringtoneSetting?.optionInputNormalSound, customSounds, siteSoundList);
    tryAddCustomSound(addressBook.ringtoneSetting?.optionInputPrioritySound, customSounds, siteSoundList);
    tryAddCustomSound(addressBook.ringtoneSetting?.optionInputUrgentSound, customSounds, siteSoundList);
  });

  return customSounds;
};
