import { Grid, Typography, Button, Divider, Alert, AlertTitle, Snackbar } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowForwardFilled from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { checkIsProdEnv } from 'shared/utils/EnvUtils';
import useSharedStyles from 'styles/useSharedStyles';

interface HeaderSectionProps {
  title: string;
  description: string;
  actionTitle: string;
  actionDescription: string;
  featureUrl: string;
  featureUrlDesc?: string;
  isNewFeature?: boolean;
}

const HeaderSection = ({
  title,
  description,
  actionTitle,
  actionDescription,
  featureUrl,
  featureUrlDesc,
  isNewFeature = false
}: HeaderSectionProps) => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();

  const alertTitle = t('BranchRequest.Alert.Title');
  const alertDesc = t('BranchRequest.Alert.Desc');
  const resendEmailBtn = t('BranchRequest.Alert.ResendEmailButton');
  const requestMembershipBtnStr = t('BranchRequest.RequestBtn');
  const requestSentBtnStr = t('BranchRequest.RequestSentBtn');
  const comingSoonStr = t('acnio:ComingSoon');
  const successAlert = t('BranchRequest.Alert.successMsg');

  const [isBranchMembershipRequested, setIsBranchMembershipRequested] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    const branchRequestStatus = localStorage.getItem('isBranchMembershipRequested');
    if (branchRequestStatus === 'true') {
      setIsBranchMembershipRequested(true);
    }
  }, []);

  const handleRequestMembershipClick = () => {
    const isProdEnv = checkIsProdEnv();
    const redirectUrl = 'https://forms.office.com/r/y3ct2Nit6T';

    if (isProdEnv) {
      window.open(redirectUrl, '_blank');
    } else {
      localStorage.setItem('isBranchMembershipRequested', 'true');
      setIsBranchMembershipRequested(true);
      setShowSuccessAlert(true);
    }
  };

  return (
    <>
      {isBranchMembershipRequested && (
        <Alert
          severity="info"
          action={
            <Button variant="text" color="primary" size="small">
              {resendEmailBtn}
            </Button>
          }
          sx={styles.alertButton}
        >
          <AlertTitle>{alertTitle}</AlertTitle>
          {alertDesc}
        </Alert>
      )}
      <Typography variant="h5" sx={sharedStyle.spacingBottom}>
        {title}
      </Typography>

      <Grid container justifyContent="space-between" spacing={8}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">{description}</Typography>
          <Button component="a" href={featureUrl} target="_blank" startIcon={<OpenInNewIcon />} color="primary">
            {featureUrlDesc}
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">{actionTitle}</Typography>
          <Typography variant="body1" sx={sharedStyle.spacingBottom}>
            {actionDescription}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<ArrowForwardFilled />}
            onClick={handleRequestMembershipClick}
            disabled={isBranchMembershipRequested || isNewFeature}
          >
            {isNewFeature ? comingSoonStr : isBranchMembershipRequested ? requestSentBtnStr : requestMembershipBtnStr}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={5000}
        onClose={() => setShowSuccessAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success">{successAlert}</Alert>
      </Snackbar>
    </>
  );
};

export default HeaderSection;

const styles = {
  divider: { borderColor: 'divider', marginY: 3 },
  alertButton: {
    alignItems: 'center'
  }
};
