import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

interface DialogWrapperProps extends DialogProps {
  header?: string;
  subheader?: string;
  wrapperContent?: React.ReactNode;
  wrapperActions?: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DialogWrapper = (props: DialogWrapperProps) => {
  const headerElemStyle =
    props.header || props.subheader
      ? [dialogStyle.header, dialogStyle.headerBorder]
      : [dialogStyle.header, dialogStyle.headerNoTitle, dialogStyle.headerBorder];
  const handleClose = () => {
    if (props.onClose) {
      props.onClose({}, 'backdropClick');
    }

    props.setIsOpen(false);
  };

  // Remove any properties that are not a part of the parent DialogProps
  const dialogProps = {
    ...props
  } as Partial<DialogWrapperProps>;
  delete dialogProps.setIsOpen;

  return (
    <>
      <Dialog {...(dialogProps as DialogProps)}>
        <Box sx={headerElemStyle}>
          {(props.header || props.subheader) && (
            <>
              <Box sx={dialogStyle.header.titleContainer}>
                <DialogTitle variant="h5" sx={dialogStyle.header.titleContainer.title} id="customized-dialog-title">
                  {props.header ? props.header : props.subheader}
                </DialogTitle>
                {props.header && props.subheader && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      {props.subheader}
                    </Typography>
                  </>
                )}
              </Box>
            </>
          )}
          <IconButton aria-label="close" onClick={handleClose} sx={dialogStyle.header.closeContainer}>
            <CloseIcon color={'action'} sx={dialogStyle.header.closeContainer.closeIcon} />
          </IconButton>
        </Box>
        <Box sx={dialogStyle.content}>{props.children}</Box>
      </Dialog>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const dialogStyle = {
  header: {
    display: 'flex',
    minHeight: '5rem',
    alignItems: 'center',
    justifyContent: 'space-between',

    // header sibling alteration
    noTitle: {
      justifyContent: 'flex-end'
    },

    closeContainer: {
      padding: '0',
      margin: '1rem 1rem 1rem 0rem',
      closeIcon: {
        fontSize: '3rem'
      }
    },
    titleContainer: {
      padding: '1rem',

      title: {
        padding: 0
      }
    }
  },
  headerBorder: {
    borderBottom: '1px solid',
    borderColor: 'rgba(0,0,0, .12)'
  },
  headerNoTitle: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: '1rem'
  }
};

export default DialogWrapper;
