// Advanced Settings - IP Address
import { Box, MenuItem, Tooltip, TextField, Card, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, IDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'styles/advancedSettingContainerStyle';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { getSite } from 'store/slices/siteSlice';
import { RootState } from 'store';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import useIPAddressValidationSchema, {
  IIPAddressFields
} from 'shared/utils/ValidationSchema/AdvancedSettings/NetworkInfo/IPAddress';
import { ActivityMessageType, RemoteManagementContext } from 'context/RemoteManagementContext';
import { GatewayCommands, GatewayCommandStatus } from 'features/RemoteManagement/types/Types';

export const IPAddressLabel = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('AdvancedSettings_Tab_Tooltip_IpAddress')}>
      <span>{t('Station.NetworkSettings.IPAddress')}</span>
    </Tooltip>
  );
};

const IPAddress = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const { associateCommand, addCustomMessageToHistory, lastJsonMessage, setSuccessMessage } =
    useContext(RemoteManagementContext);
  const selectedDevice = useSelector(getSelectedDevice);

  const site = useSelector(getSite);
  const sitePublicId = site?.siteInfo?.publicId;
  const gateway = useSelector(
    (state: RootState) =>
      state.devices.DeviceList[
        site?.siteInfo?.registeredGatewayPublicId || state.devices.DeviceListByType['GatewayAdaptor'][0]
      ]
  );
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);

  const selectedDeviceBasicInfo = useMemo(
    () => deviceList[selectedDevice.publicId]?.basicInfo,
    [deviceList, selectedDevice.publicId]
  );
  const deviceModel = useMemo(
    () => getDeviceModelNumberFromModelType(selectedDeviceBasicInfo.deviceModel, selectedDeviceBasicInfo.deviceType),
    [selectedDeviceBasicInfo.deviceModel, selectedDeviceBasicInfo.deviceType]
  );

  // Check if the user has edit permission
  const { isAllowedTo } = usePermission();
  const hasEditPermission = isAllowedTo('site:edit', sitePublicId, PermissionsContextType.SITE);

  const isDeviceIxgTgw = useMemo(() => deviceModel === 'IXGW-TGW', [deviceModel]);

  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const enumList: EnumList = fetchLocalEnumList();

  const associationTooltip = t('Association_TooltipDescription');
  const hostNameTitle = t('AdvancedSettings_HostName_Title');
  const hostNameDesc = t('AdvancedSettings_HostName_Desc');
  const ipVersionTitle = t('AdvancedSettings_IpVersion_Title');
  const ipVersionDesc = t('AdvancedSettings_IpVersion_Desc');
  const ipAddressMethodTitle = t('AdvancedSettings_IpAddressMethod_Title');
  const ipAddressMethodDesc = t('AdvancedSettings_IpAddressMethod_Desc');
  const internetConnectionTitle = t('AdvancedSettings_InternetConnection_Title');
  const internetConnectionDesc = t('AdvancedSettings_InternetConnection_Desc');
  const subnetMaskTitle = t('AdvancedSettings_SubnetMask_Title');
  const subnetMaskDesc = t('AdvancedSettings_SubnetMask_Desc');
  const ipV4DefaultGatewayTitle = t('AdvancedSettings_IpV4DefaultGateway_Title');
  const ipV4DefaultGatewayDesc = t('AdvancedSettings_IpV4DefaultGateway_Desc');
  const ipV4PrimaryDnsTitle = t('AdvancedSettings_IpV4PrimaryDns_Title');
  const ipV4PrimaryDnsDesc = t('AdvancedSettings_IpV4PrimaryDns_Desc');
  const ipV4SecondaryDnsTitle = t('AdvancedSettings_IpV4SecondaryDns_Title');
  const ipV4SecondaryDnsDesc = t('AdvancedSettings_IpV4SecondaryDns_Desc');
  const ipV4AddressTitle = t('AdvancedSettings_IpV4Address_Title');
  const ipV4AddressDesc = t('AdvancedSettings_IpV4Address_Desc');
  const ipV4MulticastAddressTitle = t('AdvancedSettings_IpV4MulticastAddress_Title');
  const ipV4MulticastAddressDesc = t('AdvancedSettings_IpV4MulticastAddress_Desc');
  const ipV4AddressErrorInvalid = t('Field.Error.Invalid', { field: ipV4AddressTitle });
  const ipV6AddressTitle = t('AdvancedSettings_IpV6Address_Title');
  const ipV6AddressDesc = t('AdvancedSettings_IpV6Address_Desc');
  const ipV6DefaultGatewayTitle = t('AdvancedSettings_IpV6DefaultGateway_Title');
  const ipV6DefaultGatewayDesc = t('AdvancedSettings_IpV6DefaultGateway_Desc');
  const ipV6PrimaryDnsTitle = t('AdvancedSettings_IpV6PrimaryDns_Title');
  const ipV6PrimaryDnsDesc = t('AdvancedSettings_IpV6PrimaryDns_Desc');
  const ipV6SecondaryDnsTitle = t('AdvancedSettings_IpV6SecondaryDns_Title');
  const ipV6SecondaryDnsDesc = t('AdvancedSettings_IpV6SecondaryDns_Desc');
  const ipV6MulticastAddressTitle = t('AdvancedSettings_IpV6MulticastAddress_Title');
  const ipV6MulticastAddressDesc = t('AdvancedSettings_IpV6MulticastAddress_Desc');
  const ipV6AddressErrorInvalid = t('Field.Error.Invalid', { field: ipV6AddressTitle });
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const saveChangesStr = t('Shared.SaveChanges');
  const buttonReset = t('Button_Reset');

  const formDevice: IIPAddressFields = {
    networkSettings: {
      hostName: selectedDevice.networkSettings?.hostName,
      subnetMask: selectedDevice.networkSettings?.subnetMask,
      ipVersion: selectedDevice.networkSettings?.ipVersion,
      ipAddressMethod: selectedDevice.networkSettings?.ipAddressMethod,
      ipV4Address: selectedDevice.networkSettings?.ipV4Address,
      ipV4DefaultGateway: selectedDevice.networkSettings?.ipV4DefaultGateway,
      ipV6Address: selectedDevice.networkSettings?.ipV6Address,
      ipV6DefaultGateway: selectedDevice.networkSettings?.ipV6DefaultGateway,
      ipV4PrimaryDns: selectedDevice.networkSettings?.ipV4PrimaryDns,
      ipV4SecondaryDns: selectedDevice.networkSettings?.ipV4SecondaryDns,
      ipV6PrimaryDns: selectedDevice.networkSettings?.ipV6PrimaryDns,
      ipV6SecondaryDns: selectedDevice.networkSettings?.ipV6SecondaryDns,
      ipV4MulticastAddress: selectedDevice.networkSettings?.ipV4MulticastAddress,
      ipV6MulticastAddress: selectedDevice.networkSettings?.ipV6MulticastAddress
    },
    gatewaySettings: {
      communicationMethod: selectedDevice.gatewaySettings?.communicationMethod
    }
  };

  const getIPAddressValidationSchema = useIPAddressValidationSchema(selectedDevice.publicId);

  const staticAssociation = async (values: any, device: IDevice) => {
    if (!gateway) {
      setErrorMessage(t('Gateway_Information_Not_Found'));
      return;
    }
    if (!hasEditPermission) {
      setErrorMessage(t('View_only_Permission'));
      return;
    }
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...values.networkSettings
    };
    if (isDeviceIxgTgw) {
      newDevice.gatewaySettings = {
        ...newDevice.gatewaySettings,
        ...values.gatewaySettings
      };
    }
    if (newDevice.networkSettings.ipVersion === 'IPV4' && !newDevice?.networkSettings?.ipV4Address) {
      setErrorMessage(ipV4AddressErrorInvalid);
      return;
    }
    if (newDevice.networkSettings.ipVersion === 'IPV6') {
      // if ipVersion is IPV6 we need to rethink all the Gateway commands
      setErrorMessage(ipV6AddressErrorInvalid);
      return;
    }

    if (!device.networkSettings?.ipVersion) {
      return;
    }

    const transactionId = crypto.randomUUID();

    const payload = {
      [device.basicInfo.macAddress]: {
        ip_ver: String(device.networkSettings.ipVersion),
        ip_addr: device.networkSettings?.ipV4Address,
        ip_subnet: device.networkSettings?.subnetMask,
        ip_gateway: device.networkSettings?.ipV4DefaultGateway || undefined,
        station_name: device.basicInfo.stationName,
        station_number: device.basicInfo.stationNumber,
        timeout_sec: '60',
        devicePublicId: device.publicId
      }
    };

    associateCommand(payload, transactionId);

    addCustomMessageToHistory({
      transactionId,
      command: GatewayCommands.ASSOCIATE,
      type: ActivityMessageType.ACTION,
      message: `Association command sent`,
      target: device.basicInfo.stationName,
      sitePublicId,
      commandPayload: payload
    });
  };

  useEffect(() => {
    if (lastJsonMessage) {
      if (
        lastJsonMessage.action === GatewayCommands.ASSOCIATE &&
        lastJsonMessage.status === GatewayCommandStatus.COMPLETED
      ) {
        setSuccessMessage(t('RemoteManagement.Errors.Success', { action: 'Association' }));
      } else if (
        lastJsonMessage.action === GatewayCommands.ASSOCIATE &&
        lastJsonMessage.status === GatewayCommandStatus.FAILED
      ) {
        setErrorMessage(t('RemoteManagement.Errors.Success', { action: 'Association' }));
      }
    }
  }, [lastJsonMessage]);

  const onSubmit = async (values: any, actions: any) => {
    const params: any = {
      device: {
        publicId: selectedDevice.publicId,
        networkSettings: {
          hostName: values.networkSettings.hostName,
          ipVersion: values.networkSettings.ipVersion,
          ipAddressMethod: values.networkSettings.ipAddressMethod
        }
      }
    };

    // Add communicationMethod only if the device is TGW
    if (isDeviceIxgTgw) {
      params.device.gatewaySettings = {
        communicationMethod: values.gatewaySettings.communicationMethod
      };
    }

    if (enumList.ipVersion[values.networkSettings.ipVersion].value === 'IPV4') {
      params.device.networkSettings.ipV4Address = values.networkSettings.ipV4Address;
      params.device.networkSettings.subnetMask =
        values.networkSettings.subnetMask === '' ? null : values.networkSettings.subnetMask;
      params.device.networkSettings.ipV4DefaultGateway =
        values.networkSettings.ipV4DefaultGateway === '' ? null : values.networkSettings.ipV4DefaultGateway;
      params.device.networkSettings.ipV4PrimaryDns =
        values.networkSettings.ipV4PrimaryDns === '' ? null : values.networkSettings.ipV4PrimaryDns;
      params.device.networkSettings.ipV4SecondaryDns =
        values.networkSettings.ipV4SecondaryDns === '' ? null : values.networkSettings.ipV4SecondaryDns;
      params.device.networkSettings.ipV4MulticastAddress =
        values.networkSettings.ipV4MulticastAddress === '' ? null : values.networkSettings.ipV4MulticastAddress;
    } else if (
      formDevice.networkSettings.ipVersion &&
      enumList.ipVersion[formDevice.networkSettings.ipVersion].value === 'IPV6'
    ) {
      params.device.networkSettings.ipV6Address = values.networkSettings.ipV6Address;
      params.device.networkSettings.ipV6DefaultGateway =
        values.networkSettings.ipV6DefaultGateway === '' ? null : values.networkSettings.ipV6DefaultGateway;
      params.device.networkSettings.ipV6PrimaryDns =
        values.networkSettings.ipV6PrimaryDns === '' ? null : values.networkSettings.ipV6PrimaryDns;
      params.device.networkSettings.ipV6SecondaryDns =
        values.networkSettings.ipV6SecondaryDns === '' ? null : values.networkSettings.ipV6SecondaryDns;
      params.device.networkSettings.ipV6MulticastAddress =
        values.networkSettings.ipV6MulticastAddress === '' ? null : values.networkSettings.ipV6MulticastAddress;
    }

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...values.networkSettings
    };
    if (isDeviceIxgTgw) {
      newDevice.gatewaySettings = {
        ...newDevice.gatewaySettings,
        ...values.gatewaySettings
      };
    }

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        } else {
          dispatch(updateSelectedDevice({ device: newDevice }));
          setShowAlert(true);
          actions.resetForm({
            values: values
          });
        }
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  if (!formikSchema) {
    setFormikSchema(getIPAddressValidationSchema(formDevice, formDevice));
  }

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
          {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                {selectedDevice.basicInfo.deviceType !== 18 ? (
                  <Tooltip title={associationTooltip} style={{ cursor: 'pointer' }}>
                    <Button
                      variant="contained"
                      onClick={() => staticAssociation(values, selectedDevice)}
                      disabled={dirty}
                    >
                      Associate Station
                    </Button>
                  </Tooltip>
                ) : null}

                <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting || isUpdating}>
                  {buttonReset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {saveChangesStr}
                </LoadingButton>
              </Box>
              <Card sx={containerStyle.settingsWrapper}>
                {!hasEditPermission && <Box sx={styles.viewPermission}>{t('View_only_Permission')}</Box>}

                <Box sx={containerStyle.gridContainer}>
                  {isDeviceIxgTgw && formDevice.gatewaySettings?.communicationMethod !== undefined ? (
                    <Grid container direction="row" justifyContent="space-between" style={containerStyle.itemContainer}>
                      <Grid item xs={12} lg={5}>
                        <Box sx={containerStyle.itemTitle}>{internetConnectionTitle}</Box>
                        <Box>{internetConnectionDesc}</Box>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Box sx={containerStyle.fieldContainer}>
                          <TextField
                            select
                            size="small"
                            label={internetConnectionTitle}
                            name="gatewaySettings.communicationMethod"
                            value={values.gatewaySettings.communicationMethod}
                            onChange={handleChange}
                            style={containerStyle.selectField}
                            helperText={
                              touched.gatewaySettings?.communicationMethod &&
                              errors.gatewaySettings?.communicationMethod
                            }
                            error={
                              touched.gatewaySettings?.communicationMethod &&
                              !!errors.gatewaySettings?.communicationMethod
                            }
                            fullWidth
                          >
                            {Object.keys(enumList.communicationMethod).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {enumList.communicationMethod[key].value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}

                  {formDevice.networkSettings.hostName !== undefined ? (
                    <Grid container direction="row" justifyContent="space-between" style={containerStyle.itemContainer}>
                      <Grid item xs={12} lg={5}>
                        <Box sx={containerStyle.itemTitle}>{hostNameTitle}</Box>
                        <Box>{hostNameDesc}</Box>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={hostNameTitle}
                            name="networkSettings.hostName"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched.networkSettings?.hostName && errors.networkSettings?.hostName}
                            error={touched.networkSettings?.hostName && !!errors.networkSettings?.hostName}
                            disabled={!hasEditPermission}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.ipAddressMethod !== undefined ? (
                    <Grid container direction="row" justifyContent="space-between" style={containerStyle.itemContainer}>
                      <Grid item xs={12} lg={5}>
                        <Box sx={containerStyle.itemTitle}>{ipAddressMethodTitle}</Box>
                        <Box>{ipAddressMethodDesc}</Box>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Box sx={containerStyle.fieldContainer}>
                          <TextField
                            select
                            size="small"
                            label={ipAddressMethodTitle}
                            name="networkSettings.ipAddressMethod"
                            value={values.networkSettings.ipAddressMethod}
                            onChange={handleChange}
                            sx={containerStyle.selectField}
                            fullWidth // Ensures the dropdown field takes the full width
                            helperText={
                              touched.networkSettings?.ipAddressMethod && errors.networkSettings?.ipAddressMethod
                            }
                            error={
                              touched.networkSettings?.ipAddressMethod && !!errors.networkSettings?.ipAddressMethod
                            }
                            disabled={!hasEditPermission}
                          >
                            {Object.keys(enumList.ipAddressMethod)
                              .filter((key) => {
                                const ipVersion: number = values?.networkSettings?.ipVersion ?? 1;
                                // If version is IpV4 and device is not a gateway, only show Static
                                if (enumList.ipVersion[ipVersion].value === 'IPV4') {
                                  if (selectedDevice.basicInfo.deviceType !== 18) {
                                    return enumList.ipAddressMethod[key].value == 'Static';
                                  } else {
                                    return (
                                      enumList.ipAddressMethod[key].value == 'Static' ||
                                      enumList.ipAddressMethod[key].value == 'DHCP'
                                    );
                                  }
                                }

                                // If version is IpV6 or device is a gateway, show all
                                return true;
                              })
                              .map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.ipAddressMethod[key].value}
                                  </MenuItem>
                                );
                              })}
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.ipVersion !== undefined ? (
                    <Grid container direction="row" justifyContent="space-between" style={containerStyle.itemContainer}>
                      <Grid item xs={12} lg={5}>
                        <Box sx={containerStyle.itemTitle}>{ipVersionTitle}</Box>
                        <Box>{ipVersionDesc}</Box>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Box sx={containerStyle.fieldContainer}>
                          <TextField
                            select
                            size="small"
                            label={ipVersionTitle}
                            name="networkSettings.ipVersion"
                            value={values.networkSettings.ipVersion}
                            style={containerStyle.selectField}
                            helperText={touched.networkSettings?.ipVersion && errors.networkSettings?.ipVersion}
                            error={touched.networkSettings?.ipVersion && !!errors.networkSettings?.ipVersion}
                            disabled={!hasEditPermission}
                            onChange={(e: any) => {
                              const selectedValue = e.target.value;
                              const newVals = {
                                ...values,
                                networkSettings: {
                                  ...values.networkSettings,
                                  ipVersion: selectedValue,
                                  // If IPV4 is selected and the device is not a gateway, set the ipAddressMethod to Static
                                  ipAddressMethod:
                                    enumList.ipVersion[selectedValue].value === 'IPV4' &&
                                    selectedDevice.basicInfo.deviceType !== 18
                                      ? 2
                                      : values.networkSettings.ipAddressMethod
                                }
                              };

                              handleChange(e);
                              setFormikSchema(getIPAddressValidationSchema(formDevice, newVals));
                            }}
                          >
                            {Object.keys(enumList.ipVersion).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {enumList.ipVersion[key].value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {values.networkSettings.ipVersion &&
                  enumList.ipVersion[values.networkSettings.ipVersion].value === 'IPV4' ? (
                    <>
                      {formDevice.networkSettings.ipV4Address !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV4AddressTitle}</Box>
                            <Box>{ipV4AddressDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV4AddressTitle}
                                key={ipV4AddressTitle}
                                name="networkSettings.ipV4Address"
                                size="small"
                                style={containerStyle.textField}
                                helperText={touched.networkSettings?.ipV4Address && errors.networkSettings?.ipV4Address}
                                error={touched.networkSettings?.ipV4Address && !!errors.networkSettings?.ipV4Address}
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.subnetMask !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{subnetMaskTitle}</Box>
                            <Box>{subnetMaskDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={subnetMaskTitle}
                                name="networkSettings.subnetMask"
                                size="small"
                                style={containerStyle.textField}
                                helperText={touched.networkSettings?.subnetMask && errors.networkSettings?.subnetMask}
                                error={touched.networkSettings?.subnetMask && !!errors.networkSettings?.subnetMask}
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV4DefaultGateway !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV4DefaultGatewayTitle}</Box>
                            <Box>{ipV4DefaultGatewayDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV4DefaultGatewayTitle}
                                key={ipV4DefaultGatewayTitle}
                                name="networkSettings.ipV4DefaultGateway"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV4DefaultGateway &&
                                  errors.networkSettings?.ipV4DefaultGateway
                                }
                                error={
                                  touched.networkSettings?.ipV4DefaultGateway &&
                                  !!errors.networkSettings?.ipV4DefaultGateway
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV4PrimaryDns !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV4PrimaryDnsTitle}</Box>
                            <Box>{ipV4PrimaryDnsDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV4PrimaryDnsTitle}
                                key={ipV4PrimaryDnsTitle}
                                name="networkSettings.ipV4PrimaryDns"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV4PrimaryDns && errors.networkSettings?.ipV4PrimaryDns
                                }
                                error={
                                  touched.networkSettings?.ipV4PrimaryDns && !!errors.networkSettings?.ipV4PrimaryDns
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV4SecondaryDns !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV4SecondaryDnsTitle}</Box>
                            <Box>{ipV4SecondaryDnsDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV4SecondaryDnsTitle}
                                key={ipV4SecondaryDnsTitle}
                                name="networkSettings.ipV4SecondaryDns"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV4SecondaryDns && errors.networkSettings?.ipV4SecondaryDns
                                }
                                error={
                                  touched.networkSettings?.ipV4SecondaryDns &&
                                  !!errors.networkSettings?.ipV4SecondaryDns
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV4MulticastAddress !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV4MulticastAddressTitle}</Box>
                            <Box>{ipV4MulticastAddressDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV4MulticastAddressTitle}
                                key={ipV4MulticastAddressTitle}
                                name="networkSettings.ipV4MulticastAddress"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV4MulticastAddress &&
                                  errors.networkSettings?.ipV4MulticastAddress
                                }
                                error={
                                  touched.networkSettings?.ipV4MulticastAddress &&
                                  !!errors.networkSettings?.ipV4MulticastAddress
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  ) : values.networkSettings.ipVersion &&
                    enumList.ipVersion[values.networkSettings.ipVersion].value === 'IPV6' ? (
                    <>
                      {formDevice.networkSettings.ipV6Address !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV6AddressTitle}</Box>
                            <Box>{ipV6AddressDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV6AddressTitle}
                                key={ipV6AddressTitle}
                                name="networkSettings.ipV6Address"
                                size="small"
                                style={containerStyle.textField}
                                helperText={touched.networkSettings?.ipV6Address && errors.networkSettings?.ipV6Address}
                                error={touched.networkSettings?.ipV6Address && !!errors.networkSettings?.ipV6Address}
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV6DefaultGateway !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV6DefaultGatewayTitle}</Box>
                            <Box>{ipV6DefaultGatewayDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV6DefaultGatewayTitle}
                                key={ipV6DefaultGatewayTitle}
                                name="networkSettings.ipV6DefaultGateway"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV6DefaultGateway &&
                                  errors.networkSettings?.ipV6DefaultGateway
                                }
                                error={
                                  touched.networkSettings?.ipV6DefaultGateway &&
                                  !!errors.networkSettings?.ipV6DefaultGateway
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV6PrimaryDns !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV6PrimaryDnsTitle}</Box>
                            <Box>{ipV6PrimaryDnsDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV6PrimaryDnsTitle}
                                key={ipV6PrimaryDnsTitle}
                                name="networkSettings.ipV6PrimaryDns"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV6PrimaryDns && errors.networkSettings?.ipV6PrimaryDns
                                }
                                error={
                                  touched.networkSettings?.ipV6PrimaryDns && !!errors.networkSettings?.ipV6PrimaryDns
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV6SecondaryDns !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV6SecondaryDnsTitle}</Box>
                            <Box>{ipV6SecondaryDnsDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV6SecondaryDnsTitle}
                                key={ipV6SecondaryDnsTitle}
                                name="networkSettings.ipV6SecondaryDns"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV6SecondaryDns && errors.networkSettings?.ipV6SecondaryDns
                                }
                                error={
                                  touched.networkSettings?.ipV6SecondaryDns &&
                                  !!errors.networkSettings?.ipV6SecondaryDns
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.ipV6MulticastAddress !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={12} lg={5}>
                            <Box sx={containerStyle.itemTitle}>{ipV6MulticastAddressTitle}</Box>
                            <Box>{ipV6MulticastAddressDesc}</Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                label={ipV6MulticastAddressTitle}
                                key={ipV6MulticastAddressTitle}
                                name="networkSettings.ipV6MulticastAddress"
                                size="small"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.ipV6MulticastAddress &&
                                  errors.networkSettings?.ipV6MulticastAddress
                                }
                                error={
                                  touched.networkSettings?.ipV6MulticastAddress &&
                                  !!errors.networkSettings?.ipV6MulticastAddress
                                }
                                disabled={!hasEditPermission}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  ) : null}
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  associateModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    AlignHorizontalCenter: 'center',
    margin: '10px'
  },
  viewPermission: {
    display: 'flex',
    fontWeight: 'bold',
    padding: '5px',
    margin: '10px',
    backgroundColor: 'neutral.semiLight'
  }
};

export default IPAddress;
