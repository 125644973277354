import { Add } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import { GridColDef, DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { RemoteManagementContext } from 'context/RemoteManagementContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import useSharedStyles from 'styles/useSharedStyles';
import { useDeleteTenantMutation, useLazyGetTenantListWithSiteIdQuery } from 'services/aiphoneCloud';
import { PermissionsContextType } from 'permissions/utils';
import { usePermission } from 'context/PermissionContext';
import EditTenantDialog from './components/EditTenantDialog';
import AddTenantDialog from './components/AddTenantDialog';

interface tenantRow {
  id: string;
  unitNumber: string;
  unitName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isPrimaryTenant: boolean;
}

interface TenantRowParams {
  row: tenantRow;
}

const TenantDataGrid = () => {
  const { setErrorMessage, setSuccessMessage } = useContext(RemoteManagementContext);
  const unitList = useAppSelector((state) => state.units.UnitList);
  const tenantList = useAppSelector((state) => state.tenants.TenantList);
  const sitePublicId = useAppSelector((state) => state.site.siteInfo.publicId);
  const sharedStyle = useSharedStyles();
  const [deleteTenant] = useDeleteTenantMutation();
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const tenantListKeys = Object.keys(tenantList);
  const { isAllowedTo } = usePermission();
  const [isEditTenantDialogOpen, setIsEditTenantDialogOpen] = useState(false);
  const [isAddTenantDialogOpen, setIsAddTenantDialogOpen] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState<string>('');
  const canTenantEdit = isAllowedTo('tenant:edit', sitePublicId, PermissionsContextType.SITE);
  const canTenantDelete = isAllowedTo('tenant:delete', sitePublicId, PermissionsContextType.SITE);

  const [tenantRows, setTenantRows] = useState<tenantRow[]>(
    tenantListKeys.map((key) => {
      return {
        id: key,
        unitNumber: unitList[tenantList[key].unitPublicId].unitNumber,
        unitName: unitList[tenantList[key].unitPublicId].unitName,
        firstName: tenantList[key].firstName,
        lastName: tenantList[key].lastName,
        email: tenantList[key].email,
        phoneNumber: tenantList[key].phoneNumber,
        isPrimaryTenant: tenantList[key].isPrimaryTenant
      };
    })
  );

  useEffect(() => {
    setTenantRows(
      tenantListKeys.map((key) => {
        return {
          id: key,
          unitNumber: unitList[tenantList[key].unitPublicId].unitNumber,
          unitName: unitList[tenantList[key].unitPublicId].unitName,
          firstName: tenantList[key].firstName,
          lastName: tenantList[key].lastName,
          email: tenantList[key].email,
          phoneNumber: tenantList[key].phoneNumber,
          isPrimaryTenant: tenantList[key].isPrimaryTenant
        };
      })
    );
  }, [unitList, tenantList]);

  const { t } = useTranslation();
  const unitNameStr = t('Unit.Name');
  const unitNumberStr = t('Unit.Number');
  const firstNameStr = t('Shared.FirstName');
  const lastNameStr = t('Shared.LastName');
  const emailStr = t('Shared.Email');
  const phoneNumberStr = t('Shared.PhoneNumber');
  const tenantStatusStr = t('Tenant.TenantStatus');
  const primaryTenantStr = t('Tenant.PrimaryTenant');
  const tenantStr = t('Tenant.Tenant', { count: 1 });
  const editTenantStr = t('Tenant.EditTenant.Title');
  const addNewTenantStr = t('RemoteManagement.Dashboard.TenantGrid.AddNewTenant');
  const deleteTenantStr = t('API.Action.DeleteTenant');
  const tenantDeletedStr = t('API.ActionSuccess', { action: deleteTenantStr });

  const dataGridStyle = {
    '& .MuiDataGrid-cell': {
      minHeight: '52px !important', // Datagrid is adding a min-height inline style, so make this important
      whiteSpace: 'nowrap'
    }
  };

  const handleEditTenant = (tenantPublicId: string) => () => {
    setSelectedTenantId(tenantPublicId);
    setIsEditTenantDialogOpen(true);
  };

  const handleDeleteTenant = async (tenantPublicId: string) => {
    try {
      await deleteTenant(tenantPublicId);
      fetchTenants({ sitePublicId: sitePublicId, qty: -1, page: 0 });
      setSuccessMessage(tenantDeletedStr);
    } catch (error) {
      setErrorMessage(error as string);
    }
  };

  const TenantDataGridToolbar = () => {
    return (
      <>
        <Box
          sx={[
            sharedStyle.toolbarContainer,
            sharedStyle.toolbarContainer.padded,
            sharedStyle.toolbarContainer.rightAligned
          ]}
        >
          <Button startIcon={<Add />} onClick={() => setIsAddTenantDialogOpen(true)}>
            {addNewTenantStr}
          </Button>
        </Box>
      </>
    );
  };

  const tenantGridColumns: GridColDef[] = [
    { field: 'unitNumber', headerName: unitNumberStr, flex: 0.65 },
    { field: 'unitName', headerName: unitNameStr, flex: 0.65 },
    { field: 'firstName', headerName: firstNameStr, flex: 0.65 },
    { field: 'lastName', headerName: lastNameStr, flex: 0.65 },
    { field: 'email', headerName: emailStr, flex: 1 },
    { field: 'phoneNumber', headerName: phoneNumberStr, flex: 0.65 },
    {
      field: 'isPrimaryTenant',
      headerName: tenantStatusStr,
      flex: 0.91,
      renderCell: (params) => (
        <Chip
          sx={sharedStyle.chip}
          color={params.row.isPrimaryTenant ? 'primary' : 'default'}
          size="medium"
          variant="outlined"
          label={<Typography variant="body2">{params.row.isPrimaryTenant ? primaryTenantStr : tenantStr}</Typography>}
        />
      )
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      filterable: false,
      getActions: (params: TenantRowParams) =>
        [
          canTenantEdit && (
            <GridActionsCellItem label={editTenantStr} onClick={handleEditTenant(params.row.id)} showInMenu={true} />
          ),
          canTenantDelete && (
            <GridActionsCellItem
              label={deleteTenantStr}
              onClick={() => {
                handleDeleteTenant(params.row.id);
              }}
              showInMenu={true}
            />
          )
        ].filter(Boolean) as JSX.Element[]
    }
  ];

  return (
    <>
      <DataGrid
        columns={tenantGridColumns}
        rows={tenantRows}
        getRowHeight={() => 'auto'}
        sx={dataGridStyle}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 }
          }
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{ toolbar: TenantDataGridToolbar }}
      />
      <EditTenantDialog
        isOpen={isEditTenantDialogOpen}
        setIsOpen={setIsEditTenantDialogOpen}
        selectedTenantId={selectedTenantId}
      />
      <AddTenantDialog isOpen={isAddTenantDialogOpen} setIsOpen={setIsAddTenantDialogOpen} />
    </>
  );
};

export default TenantDataGrid;
