import { GatewayCommands, GatewayCommandStatus, GatewayStatus } from 'features/RemoteManagement/types/Types';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomMessageEvent } from './SiteContext';
import CONFIG from 'config';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAppSelector } from 'store/hooks';
import { getGatewayMacAddress, getGatewayPublicId } from 'store/slices/devicesSlice';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { UUID } from 'crypto';
import {
  useLazyGetDeviceListWithSitePublicIdQuery,
  useLazyGetRegisteredGatewayWithPublicIdQuery
} from 'services/aiphoneCloud';

interface RemoteManagementProviderProps {
  children?: ReactNode;
}

export interface WebSocketMessage {
  data: any;
  timeStamp: number;
  status: GatewayCommandStatus;
  transactionId: string;
  action: GatewayCommands;
  currentStep: number;
  totalSteps: number;
  payload?: any;
  message?: string;
  devicePublicId?: string;
  errorCode?: string;
}

interface SyncDevicePayload {
  mac_addr: string;
  station_type: string;
  devicePublicId: string;
}

export interface AssociateDevicePayload {
  [key: string]: {
    ip_ver: string;
    ip_addr: string;
    ip_subnet: string;
    ip_gateway: string | undefined;
    station_name: string;
    station_number: string;
    timeout_sec: string;
    devicePublicId: string;
  };
}

export interface SyncStationSoundsStation {
  devicePublicId: string;
  mac_addr: string;
  station_type: string;
  id: string;
  pw: string;
}

export interface SyncStationSoundsSound {
  sound_file_name: string;
}

export interface SyncStationSoundsPayload {
  [key: string]: SyncStationSoundsStation | SyncStationSoundsSound;
}

export enum ActivityMessageType {
  INFO = 'info',
  ACTION = 'action'
}

export enum QueuedStatus {
  QUEUED,
  THROTTLED,
  DUPLICATE,
  OFFLINE
}
export const QueueThrottleTime = 2000;

interface RemoteManagementContextProps {
  successMessage: string;
  setSuccessMessage: (message: string) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  gatewayStatus: GatewayStatus;
  setGatewayStatus: (newState: GatewayStatus) => void;
  activityItems: any[];
  setActivityItems: (newItems: any[]) => void;
  isSyncingWithAcl: boolean;
  setIsSyncingWithAcl: (newState: boolean) => void;
  gatewayFirmwareVersion: string | undefined;
  setGatewayFirmwareVersion: (newState: string | undefined) => void;
  gatewayMacAddress: string;
  gatewayPublicId: string | null;
  isSearching: boolean;
  setIsSearching: (newState: boolean) => void;
  stationsFromSearch: any[];
  setStationsFromSearch: (newStations: any[]) => void;
  isSyncing: boolean;
  setIsSyncing: (newState: boolean) => void;
  isSyncingSounds: boolean;
  setIsSyncingSounds: (newState: boolean) => void;
  isUpdating: boolean;
  setIsUpdating: (newState: boolean) => void;
  isChecking: boolean;
  setIsChecking: (newState: boolean) => void;
  messageHistory: CustomMessageEvent<any>[];
  messageQueue: any[];
  currentMessage: any | null;
  isProcessingQueue: boolean;
  sendJsonMessage: (message: any) => void;
  lastJsonMessage: WebSocketMessage | null;
  readyState: ReadyState;
  connectionStatus: string;
  addMessageToQueue: (message: any) => QueuedStatus;
  addCustomMessageToHistory: (message: any) => void;
  searchCommand: () => void;
  infoCommand: () => void;
  registerCommand: (
    macAddress: string,
    existingId?: string,
    existingPw?: string,
    deviceModel?: number,
    gatewayPublicId?: string
  ) => void;
  syncCommand: (devicesToSync: Record<string, SyncDevicePayload>, transactionId?: UUID) => void;
  customSoundSyncCommand: (syncPayload: SyncStationSoundsPayload, transactionId?: UUID) => void;
  associateCommand: (devicePayload: AssociateDevicePayload, transactionId?: UUID) => QueuedStatus;
  rebootCommand: () => void;
  updateFirmwareCommand: (payload: any) => void;
  testCommand: () => void;
}

const initialValues = {
  successMessage: '',
  setSuccessMessage: () => undefined,
  errorMessage: '',
  setErrorMessage: () => undefined,
  gatewayStatus: GatewayStatus.OFFLINE,
  setGatewayStatus: () => undefined,
  activityItems: [],
  setActivityItems: () => undefined,
  isSyncingWithAcl: false,
  setIsSyncingWithAcl: () => undefined,
  gatewayFirmwareVersion: undefined,
  setGatewayFirmwareVersion: () => undefined,
  gatewayMacAddress: '',
  gatewayPublicId: '',
  isSearching: false,
  setIsSearching: () => undefined,
  stationsFromSearch: [],
  setStationsFromSearch: () => undefined,
  isSyncing: false,
  setIsSyncing: () => undefined,
  isSyncingSounds: false,
  setIsSyncingSounds: () => undefined,
  isUpdating: false,
  setIsUpdating: () => undefined,
  isChecking: false,
  setIsChecking: () => undefined,
  messageHistory: [],
  messageQueue: [],
  currentMessage: null,
  isProcessingQueue: false,
  sendJsonMessage: () => undefined,
  lastJsonMessage: null,
  readyState: ReadyState.UNINSTANTIATED,
  connectionStatus: 'Uninstantiated',
  addMessageToQueue: () => QueuedStatus.OFFLINE,
  addCustomMessageToHistory: () => undefined,
  searchCommand: () => undefined,
  infoCommand: () => undefined,
  registerCommand: () => undefined,
  syncCommand: () => undefined,
  customSoundSyncCommand: () => undefined,
  associateCommand: () => QueuedStatus.OFFLINE,
  rebootCommand: () => undefined,
  updateFirmwareCommand: () => undefined,
  testCommand: () => undefined
};

const RemoteManagementContext = createContext<RemoteManagementContextProps>(initialValues);

const RemoteManagementProvider = ({ children }: RemoteManagementProviderProps) => {
  /****************************************
   * Section: Site State Management
   ****************************************/
  const siteInfo = useAppSelector((state) => state.site.siteInfo);
  const sitePublicId = siteInfo.publicId;
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  /****************************************
   * Section: Gateway State Management
   ****************************************/
  const [gatewayStatus, setGatewayStatus] = useState<GatewayStatus>(GatewayStatus.OFFLINE);
  const [activityItems, setActivityItems] = useState<any[]>([]);
  const [isSyncingWithAcl, setIsSyncingWithAcl] = useState(false);
  const [gatewayFirmwareVersion, setGatewayFirmwareVersion] = useState<string | undefined>(undefined);
  const gatewayMacAddress = useAppSelector(getGatewayMacAddress);
  const gatewayPublicId = useAppSelector(getGatewayPublicId);
  const [fetchGateway] = useLazyGetRegisteredGatewayWithPublicIdQuery();

  /****************************************
   * Section: Command State Management
   ****************************************/
  const [isSearching, setIsSearching] = useState(false);
  const [stationsFromSearch, setStationsFromSearch] = useState<any[]>([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSyncingSounds, setIsSyncingSounds] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  /****************************************
   * Section: WebSocket State Management
   ****************************************/
  const [messageHistory, setMessageHistory] = useState<CustomMessageEvent<any>[]>([]);
  const [messageQueue, setMessageQueue] = useState<any[]>([]);
  const [currentMessage, setCurrentMessage] = useState<any | null>(null);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));

  /****************************************
   * Section: WebSocket Initialization
   ****************************************/
  const socketUrl = `${CONFIG.websocketEndpoint}?token=${token}`;
  const lastMessageTimestamp = useRef<number | null>(null);
  const processedMessages = useRef<Set<string>>(new Set());
  const { sendJsonMessage, lastJsonMessage, lastMessage, readyState } = useWebSocket<WebSocketMessage>(socketUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: (attemptNumber) => Math.min(15000, 2000 * 2 ** attemptNumber),
    onClose: () => {
      const newToken = localStorage.getItem('token');
      setToken(newToken);
    },
    onError: (event) => {
      console.error('WebSocket error:', event);
    }
  });

  const connectionStatus = useMemo(() => {
    return {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
    }[readyState];
  }, [readyState]);

  /****************************************
   * Section: WebSocket Message Processing
   ****************************************/
  const addMessageToQueue = useCallback((message: any): QueuedStatus => {
    if (processedMessages.current.has(message.transactionId)) return QueuedStatus.DUPLICATE; // Avoid duplicate messages
    const now = Date.now();
    if (!lastMessageTimestamp.current || now - lastMessageTimestamp.current > QueueThrottleTime) {
      lastMessageTimestamp.current = now;
      setMessageQueue((prev) => [...prev, message]);
      return QueuedStatus.QUEUED;
    } else {
      return QueuedStatus.THROTTLED;
    }
  }, []);

  const addCustomMessageToHistory = (customMessage: any) => {
    const messageWithTimestamp = {
      data: JSON.stringify(customMessage),
      absoluteTimeStamp: Date.now()
    };
    setMessageHistory((prev) => prev.concat(messageWithTimestamp));
  };

  const processNextMessage = useCallback(() => {
    setMessageQueue((prevQueue) => {
      if (prevQueue.length === 0 || isProcessingQueue) return prevQueue;

      const nextMessage = prevQueue[0];
      if (processedMessages.current.has(nextMessage.transactionId)) {
        return prevQueue.slice(1);
      }

      setCurrentMessage(nextMessage);
      setIsProcessingQueue(true);
      sendJsonMessage(nextMessage);

      return prevQueue.slice(1);
    });
  }, [isProcessingQueue, sendJsonMessage]);

  useEffect(() => {
    if (!isProcessingQueue && messageQueue.length > 0) {
      processNextMessage();
    }
  }, [messageQueue, isProcessingQueue, processNextMessage]);

  useEffect(() => {
    if (lastMessage) {
      const parsedMessage = JSON.parse(lastMessage.data);
      const messageWithTimestamp = {
        data: lastMessage.data,
        absoluteTimeStamp: Date.now(),
        timeStamp: lastMessage.timeStamp
      };
      setMessageHistory((prev) => prev.concat(messageWithTimestamp));

      if (
        parsedMessage.status === GatewayCommandStatus.COMPLETED ||
        parsedMessage.status === GatewayCommandStatus.FAILED
      ) {
        setCurrentMessage(null);
        setIsProcessingQueue(false);
        processedMessages.current.add(parsedMessage.transactionId);
      }
    }
  }, [lastMessage]);

  /****************************************
   * Section: WebSocket Message Handling
   ****************************************/
  useEffect(() => {
    const handleLastJsonMessage = async () => {
      if (!lastJsonMessage) return;

      switch (lastJsonMessage.action) {
        case GatewayCommands.INFO:
          if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
            setGatewayStatus(GatewayStatus.ONLINE);
            await fetchGateway(gatewayPublicId);
          } else if (
            lastJsonMessage.status === GatewayCommandStatus.SUCCESS ||
            lastJsonMessage.status === GatewayCommandStatus.PENDING
          ) {
            setGatewayStatus(GatewayStatus.CHECKING);
          } else {
            setGatewayStatus(GatewayStatus.OFFLINE);
          }
          break;
        case GatewayCommands.TEST:
          setGatewayStatus(GatewayStatus.CHECKING);
          if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
            setGatewayStatus(GatewayStatus.ONLINE);
          }
          break;
        case GatewayCommands.REGISTER:
          if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
            if (lastJsonMessage.message?.includes('Cloud Gateway registered successfully')) {
              setGatewayFirmwareVersion(lastJsonMessage.payload.fw_ver);
            }
            setGatewayStatus(GatewayStatus.ONLINE);
          }
          break;
        case GatewayCommands.SEARCH:
          if (lastJsonMessage.status === GatewayCommandStatus.PENDING) {
            setStationsFromSearch(lastJsonMessage.payload);
          }
          if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
            setStationsFromSearch((prevStations) => [...prevStations, ...lastJsonMessage.payload]);
            setIsSearching(false);
          }
          break;
        case GatewayCommands.FIRMWARE_UPDATE:
          if (lastJsonMessage.currentStep !== lastJsonMessage.totalSteps) {
            setGatewayStatus(GatewayStatus.UPDATING);
          }
          if (
            lastJsonMessage.status === GatewayCommandStatus.COMPLETED ||
            lastJsonMessage.status === GatewayCommandStatus.FAILED
          ) {
            await fetchGateway(gatewayPublicId);
            setIsUpdating(false);
          }
          break;
        case GatewayCommands.SYNC:
          if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
            await fetchDevices({
              qty: '-1',
              page: 0,
              sitePublicId
            });
            setIsSyncing(false);
          }
          if (lastJsonMessage.status === GatewayCommandStatus.FAILED) {
            setIsSyncing(false);
          }
          break;
        case GatewayCommands.CUSTOM_SOUND_SYNC:
          if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
            setIsSyncingSounds(false);
          }
          if (lastJsonMessage.status === GatewayCommandStatus.FAILED) {
            setIsSyncingSounds(false);
          }
          break;
        default:
          break;
      }
    };

    const debounceTimeout = setTimeout(handleLastJsonMessage, 50); // Debounce for 50ms
    return () => clearTimeout(debounceTimeout);
  }, [lastJsonMessage]);

  /****************************************
   * Section: Commands
   ****************************************/
  const infoCommand = () => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.INFO,
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        transactionId: crypto.randomUUID()
      });
    }
  };

  const searchCommand = () => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.SEARCH,
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        transactionId: crypto.randomUUID()
      });
      setIsSearching(true);
    }
  };

  const registerCommand = (
    macAddress: string,
    existingId?: string,
    existingPw?: string,
    deviceModel?: number,
    gatewayPublicId?: string
  ) => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.REGISTER,
        transactionId: crypto.randomUUID(),
        macAddress,
        deviceModel: deviceModel,
        devicePublicId: gatewayPublicId,
        gatewayId: existingId,
        gatewayPassword: existingPw,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: siteInfo.publicId
      });
    }
  };

  const syncCommand = (devicesToSync: Record<string, SyncDevicePayload>, transactionId?: UUID, target?: string) => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.SYNC,
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        transactionId: transactionId || crypto.randomUUID(),
        target: target || undefined,
        payload: devicesToSync
      });
      setIsSyncing(true);
    }
  };

  const customSoundSyncCommand = (syncPayload: SyncStationSoundsPayload, transactionId?: UUID) => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.CUSTOM_SOUND_SYNC,
        transactionId: transactionId || crypto.randomUUID(),
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        payload: syncPayload
      });
      setIsSyncingSounds(true);
    }
  };

  const associateCommand = (devicePayload: AssociateDevicePayload, transactionId?: UUID): QueuedStatus => {
    if (readyState === ReadyState.OPEN) {
      return addMessageToQueue({
        action: GatewayCommands.ASSOCIATE,
        transactionId: transactionId || crypto.randomUUID(),
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        payload: devicePayload
      });
    }

    return QueuedStatus.OFFLINE;
  };

  const rebootCommand = () => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.REBOOT,
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        transactionId: crypto.randomUUID()
      });
    }
  };

  const updateFirmwareCommand = (payload: any) => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.FIRMWARE_UPDATE,
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        transactionId: crypto.randomUUID(),
        payload
      });
      setIsUpdating(true);
    }
  };

  const testCommand = () => {
    if (readyState === ReadyState.OPEN) {
      addMessageToQueue({
        action: GatewayCommands.TEST,
        macAddress: gatewayMacAddress,
        siteId: siteInfo.awsPropertyId,
        sitePublicId: sitePublicId,
        devicePublicId: gatewayPublicId,
        transactionId: crypto.randomUUID(),
        payload: {}
      });
    }
  };

  const contextValue = useMemo(
    () => ({
      successMessage,
      setSuccessMessage,
      errorMessage,
      setErrorMessage,
      gatewayStatus,
      setGatewayStatus,
      activityItems,
      setActivityItems,
      isSyncingWithAcl,
      setIsSyncingWithAcl,
      gatewayFirmwareVersion,
      setGatewayFirmwareVersion,
      gatewayMacAddress,
      gatewayPublicId,
      isSearching,
      setIsSearching,
      stationsFromSearch,
      setStationsFromSearch,
      isSyncing,
      setIsSyncing,
      isSyncingSounds,
      setIsSyncingSounds,
      isUpdating,
      setIsUpdating,
      isChecking,
      setIsChecking,
      messageHistory,
      messageQueue: messageQueue,
      currentMessage,
      isProcessingQueue,
      sendJsonMessage,
      lastJsonMessage,
      readyState,
      connectionStatus,
      addMessageToQueue,
      addCustomMessageToHistory,
      searchCommand,
      infoCommand,
      registerCommand,
      syncCommand,
      customSoundSyncCommand,
      associateCommand,
      rebootCommand,
      updateFirmwareCommand,
      testCommand
    }),
    [
      successMessage,
      errorMessage,
      gatewayStatus,
      activityItems,
      isSyncingWithAcl,
      setIsSyncingWithAcl,
      gatewayFirmwareVersion,
      setGatewayFirmwareVersion,
      gatewayMacAddress,
      gatewayPublicId,
      isSearching,
      stationsFromSearch,
      isSyncing,
      isSyncingSounds,
      isUpdating,
      isChecking,
      messageHistory,
      currentMessage,
      isProcessingQueue,
      sendJsonMessage,
      lastJsonMessage,
      readyState,
      connectionStatus,
      addMessageToQueue,
      addCustomMessageToHistory,
      searchCommand,
      infoCommand,
      registerCommand,
      syncCommand,
      customSoundSyncCommand,
      associateCommand,
      rebootCommand,
      updateFirmwareCommand,
      testCommand
    ]
  );

  return (
    <RemoteManagementContext.Provider value={contextValue}>
      {children}
      <SnackbarAlert
        type="success"
        time={5000}
        text={successMessage}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={5000}
        text={errorMessage}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
    </RemoteManagementContext.Provider>
  );
};

export { RemoteManagementContext, RemoteManagementProvider };
