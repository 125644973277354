/* eslint-disable unused-imports/no-unused-vars */ // TODO Remove this after implementing the feature
import { Button, FormControlLabel, Typography, DialogActions, Grid, RadioGroup, Radio, Alert } from '@mui/material';
import { Form, Formik } from 'formik';
import { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { fetchLocalEnumList, IDeviceModelValue } from 'shared/utils/EnumUtils';
import GatewayMacInput from './forms/GatewayMacInput';
import * as Yup from 'yup';
import { VALID_MAC_ADDRESS_REGEX } from 'shared/constants/regex';
import GatewaySIMInfo from './GatewaySIMInfo';
import useSharedStyles from 'styles/useSharedStyles';
import { GatewayCommands, GatewayCommandStatus, GatewayStatus } from 'features/RemoteManagement/types/Types';
import { useAppSelector } from 'store/hooks';
import { LoadingButton } from '@mui/lab';
import {
  useCreateDeviceMutation,
  useLazyGetRegisteredGatewayWithPublicIdQuery,
  useUpdateSiteMutation
} from 'services/aiphoneCloud';
import { useDispatch } from 'react-redux';
import { setRegisteredGateway } from 'store/slices/siteSlice';
import { RemoteManagementContext } from 'context/RemoteManagementContext';
import { getSerialNumber } from 'shared/utils/helperFunctions';
import CONFIG from 'config';

interface Props {
  gatewayType: number;
  handleNextStep: () => void;
  handleBackStep: () => void;
  gatewayPublicId: string | undefined;
  setGatewayPublicId: Dispatch<SetStateAction<string | undefined>>;
}

const FinishRegistration = ({
  gatewayType,
  handleNextStep,
  handleBackStep,
  gatewayPublicId,
  setGatewayPublicId
}: Readonly<Props>) => {
  const sharedStyle = useSharedStyles();
  const site = useAppSelector((state) => state.site);
  const [isUsingSim, setIsUsingSim] = useState(false);
  const [isGatewayOnline, setIsGatewayOnline] = useState(false);
  const [inputMacAddress, setInputMacAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gatewayErrorMessage, setGatewayErrorMessage] = useState('');
  const [gatewayErrorCode, setGatewayErrorCode] = useState('');
  const [existingId, setExistingId] = useState<string | undefined>(undefined);
  const [existingPw, setExistingPw] = useState<string | undefined>(undefined);
  const [macAddressConfirmed, setMacAddressConfirmed] = useState(false);
  const [hasSIMSubscription, setHasSIMSubscription] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fetchGateway] = useLazyGetRegisteredGatewayWithPublicIdQuery();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const [createDevice] = useCreateDeviceMutation();
  const [updateSite] = useUpdateSiteMutation();
  const dispatch = useDispatch();
  const enumList = fetchLocalEnumList();
  const tgwDevice = enumList.deviceModel[
    Object.keys(enumList.deviceModel).find((key) => {
      return enumList.deviceModel[key].value === 'IXGW-TGW';
    }) as string
  ] as IDeviceModelValue;
  const gwDevice = enumList.deviceModel[
    Object.keys(enumList.deviceModel).find((key) => {
      return enumList.deviceModel[key].value === 'IXGW-GW';
    }) as string
  ] as IDeviceModelValue;
  const { registerCommand, lastJsonMessage, setGatewayStatus } = useContext(RemoteManagementContext);

  const { t } = useTranslation();
  const modelNameObj = {
    modelName: gatewayType === tgwDevice.modelNumber ? tgwDevice.value : gwDevice.value
  };
  const backStr = t('Shared.Back');
  const registerGatewayStr = t('Site.Configure.RegisterGateway.Title');
  const isGatewayWiredStr = t('Site.Configure.RegisterGateway.FinishRegistration.IsGatewayWired');
  const remoteManagementHeaderStr = t(
    'Site.Configure.RegisterGateway.FinishRegistration.RemoteManagement.Header',
    modelNameObj
  );
  const remoteManagementConnectStr = t(
    'Site.Configure.RegisterGateway.FinishRegistration.RemoteManagement.Connect',
    modelNameObj
  );
  const macAddressHeaderStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.Header', modelNameObj);
  const macAddressLocationStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.Location');
  const macAddressPowerCycleStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.PowerCycle');
  const enterValidMacStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.EnterValidMac');
  const checkGatewayConnectionStr = t('Site.Configure.RegisterGateway.FinishRegistration.CheckGatewayConnection');
  const dhcpArticleUrl = 'https://www.aiphone.com';
  const macHelpUrl = 'https://www.aiphone.com';
  const gatewayDefaultStationNumber = '9999';
  const stationAlreadyExistsStr = t('Site.Configure.RegisterGateway.FinishRegistration.StationAlreadyExists', {
    stationNumber: gatewayDefaultStationNumber
  });

  // This is how to interpolate a link into a localized string.
  const remoteManagementDHCPStr: ReactElement = (
    <Trans
      t={t}
      i18nKey="Site.Configure.RegisterGateway.FinishRegistration.RemoteManagement.DHCP"
      components={{ a: <a href={dhcpArticleUrl} target="_blank" rel="noopener noreferrer" /> }}
    />
  );
  const havingTroubleStr: ReactElement = (
    <Trans
      t={t}
      i18nKey="Site.Configure.RegisterGateway.FinishRegistration.MACAddress.HavingTrouble"
      components={{ a: <a href={macHelpUrl} target="_blank" rel="noopener noreferrer" /> }}
    />
  );

  const wiredStr = t('Site.Configure.RegisterGateway.FinishRegistration.Wired');
  const simStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIM');
  const cloudGatewayStr = t('Shared.CloudGateway');
  const aclUploadActionStr = t('API.Action.SyncACL.Upload');
  const getIntercomAccessDateActionStr = t('API.Action.SyncACL.GetIntercomAccessDate');
  const aclUploadErrorStr = t('API.Error.Generic', { action: aclUploadActionStr });
  const getIntercomAccessDateErrorStr = t('API.Error.Generic', { action: getIntercomAccessDateActionStr });

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.action === GatewayCommands.REGISTER) {
        switch (lastJsonMessage.status) {
          case GatewayCommandStatus.COMPLETED:
            setIsLoading(false);
            setMacAddressConfirmed(true);
            if (lastJsonMessage.devicePublicId !== null) {
              fetchGateway(lastJsonMessage.devicePublicId).then((response) => {
                if (response.data) {
                  dispatch(setRegisteredGateway(lastJsonMessage.devicePublicId));
                  setIsGatewayOnline(true);
                  setGatewayStatus(GatewayStatus.ONLINE);
                }
              });
            }
            handleNextStep();
            break;
          case GatewayCommandStatus.FAILED:
            setGatewayErrorMessage(lastJsonMessage.message as string);
            setGatewayErrorCode(lastJsonMessage.errorCode as string);
            setIsLoading(false);
            break;
          default:
            break;
        }
      }
    }
  }, [lastJsonMessage]);

  interface formValues {
    isUsingSim: boolean;
    macAddress: string;
  }

  const onSubmit = (values: formValues) => {
    setIsLoading(true);
    registerCommand(values.macAddress, existingId, existingPw, gatewayType, gatewayPublicId);
  };

  const handleDBError = (error: any) => {
    const parsedError = JSON.parse(error.data);
    if (parsedError.errorDetails.includes('unique')) {
      setErrorMessage(stationAlreadyExistsStr);
    }

    setIsLoading(false);
  };

  /**
   * @description This function is called when the user clicks "Confirm Mac Address" button
   * The purpose of the function is to create a new gateway device in the database and update the site with the new gateway public id
   * The function will also send a request to the ACL to upload the site data for SIM Billing to have a record of the site with the gateway
   *
   * @returns void
   */
  const handleEnableSIMSite = async () => {
    setIsLoading(true);

    const aclToken = localStorage.getItem('acltoken');
    const callingUserPublicId = currentUser?.publicId as string;
    let newGatewayPublicId;

    try {
      const createdDeviceResponse = await createDevice({
        sitePublicId: site.siteInfo.publicId,
        devices: [
          {
            deviceType: 18,
            deviceModel: gatewayType,
            macAddress: inputMacAddress,
            stationNumber: gatewayDefaultStationNumber,
            stationName: cloudGatewayStr,
            advancedSettings: {
              networkSettings: {
                ipV4Address: '192.168.1.160',
                subnetMask: '255.255.255.0'
              },
              gatewaySettings: {
                gatewaySerialNumber: getSerialNumber(inputMacAddress),
                communicationMethod: 3
              }
            }
          }
        ]
      }).unwrap();
      newGatewayPublicId = createdDeviceResponse[0].publicId;
      setGatewayPublicId(newGatewayPublicId);
    } catch (error: any) {
      setIsLoading(false);
      handleDBError(error);
      return;
    }

    try {
      await updateSite({
        updatedSite: {
          publicId: site.siteInfo.publicId,
          awsPropertyId: site.siteInfo?.awsPropertyId,
          registeredGatewayPublicId: newGatewayPublicId
        }
      });
    } catch (error: any) {
      setIsLoading(false);
      handleDBError(error);
      return;
    }

    try {
      await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'upload',
          propertyId: site.siteInfo.awsPropertyId,
          token: aclToken,
          siteId: site.siteInfo.publicId,
          userId: callingUserPublicId
        })
      });
    } catch (error) {
      throw new Error(aclUploadErrorStr);
    }

    //Wait for 5 seconds for ACL to process the site data
    await new Promise((resolve) => setTimeout(resolve, 5000));

    try {
      await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'getIntercomAccessDate',
          propertyId: site.siteInfo.awsPropertyId,
          token: aclToken,
          clientId: getSerialNumber(inputMacAddress),
          userId: callingUserPublicId
        })
      });
      setMacAddressConfirmed(true);
      setIsLoading(false);
    } catch (error) {
      throw new Error(getIntercomAccessDateErrorStr);
    }
  };

  const formValues = {
    isUsingSim: isUsingSim,
    macAddress: inputMacAddress
  };

  // write a yup schema that validates all three checkboxes are true
  const introductionValidation = Yup.object().shape({
    macAddress: Yup.string().required().matches(VALID_MAC_ADDRESS_REGEX)
  });

  return (
    <Formik initialValues={formValues} validationSchema={introductionValidation} onSubmit={onSubmit}>
      {({ setFieldValue, values, errors, touched }) => {
        const handleIsUsingSimChange = (value: boolean) => {
          setIsUsingSim(value);
          setFieldValue('isUsingSim', value);
        };

        const handleMacAddressChange = (macAddress: string) => {
          setInputMacAddress(macAddress);
          setFieldValue('macAddress', macAddress);
        };

        return (
          <Form>
            <Grid container sx={[sharedStyle.gridContainer]}>
              {gatewayType === tgwDevice.modelNumber && (
                <Grid
                  container
                  item
                  sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md, sharedStyle.common.width.fit]}
                >
                  <Grid item>
                    <Typography variant="h6">{isGatewayWiredStr}</Typography>
                  </Grid>
                  <Grid item>
                    <RadioGroup name="isUsingSim" value={values.isUsingSim}>
                      <FormControlLabel
                        sx={sharedStyle.radioItem}
                        key={'Wired'}
                        value={false}
                        control={<Radio />}
                        label={wiredStr}
                        onChange={() => {
                          handleIsUsingSimChange(false);
                        }}
                      />
                      <FormControlLabel
                        sx={sharedStyle.radioItem}
                        key={'SIM'}
                        value={true}
                        control={<Radio />}
                        label={simStr}
                        onChange={() => {
                          handleIsUsingSimChange(true);
                        }}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              )}
              <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
                <Typography variant="h6">{remoteManagementHeaderStr}</Typography>
                <Typography variant="body1">{remoteManagementConnectStr}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {remoteManagementDHCPStr}
                </Typography>
              </Grid>
              <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
                <Typography variant="h6">{macAddressHeaderStr}</Typography>
                <Typography variant="body1">{macAddressLocationStr}</Typography>
                <Alert severity="info">
                  <Typography variant="body1">{macAddressPowerCycleStr}</Typography>
                </Alert>
                <GatewayMacInput
                  modelName={modelNameObj.modelName}
                  inputMacAddress={inputMacAddress}
                  handleMacAddressChange={handleMacAddressChange}
                  isGatewayOnline={isGatewayOnline}
                  gatewayErrorCode={gatewayErrorCode}
                  gatewayErrorMessage={gatewayErrorMessage}
                  existingId={existingId}
                  existingPw={existingPw}
                  setExistingId={setExistingId}
                  setExistingPw={setExistingPw}
                  isLoading={isLoading}
                  isUsingSim={isUsingSim}
                  macAddressConfirmed={macAddressConfirmed}
                />
                <Typography variant="body2" color="textSecondary">
                  {havingTroubleStr}
                </Typography>
              </Grid>
              <Grid item>
                {isUsingSim && macAddressConfirmed && (
                  <GatewaySIMInfo
                    hasSIMSubscription={hasSIMSubscription}
                    setHasSIMSubscription={setHasSIMSubscription}
                  />
                )}
              </Grid>
              <Grid item>
                <DialogActions sx={sharedStyle.common.padding.none}>
                  <Grid
                    container
                    sx={[
                      sharedStyle.gridContainer,
                      sharedStyle.common.padding.none,
                      sharedStyle.common.gap.xs,
                      sharedStyle.gridContainer.action
                    ]}
                  >
                    <Grid item>
                      <Typography
                        sx={
                          errorMessage || (errors.macAddress && touched.macAddress) ? null : sharedStyle.common.hideItem
                        }
                        variant="error"
                      >
                        {errorMessage || enterValidMacStr}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={handleBackStep}>
                        {backStr}
                      </Button>
                      {!isUsingSim || hasSIMSubscription ? (
                        <LoadingButton loading={isLoading} type="submit" variant="contained">
                          {registerGatewayStr}
                        </LoadingButton>
                      ) : isUsingSim && !macAddressConfirmed ? (
                        <LoadingButton loading={isLoading} variant="contained" onClick={handleEnableSIMSite}>
                          {checkGatewayConnectionStr}
                        </LoadingButton>
                      ) : null}
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FinishRegistration;
