import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RegexSubnetMask } from 'features/RemoteManagement/types/Types';

export const subnetMaskBlacklist: string[] = ['0.0.0.0'];

const useSubnetMaskValidationSchema = (fieldName: string): Yup.Schema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid = t('Field.Error.Invalid', { field: fieldName });

  return Yup.string()
    .nullable()
    .notOneOf(subnetMaskBlacklist, formattedFieldInvalid)
    .matches(RegexSubnetMask, formattedFieldInvalid);
};

export default useSubnetMaskValidationSchema;
