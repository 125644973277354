import {
  useGetUsersWithBranchRolesQuery,
  useGetUsersWithGlobalRolesQuery,
  useGetUsersWithNoBranchRolesQuery,
  useGetUsersByBranchPublicIdQuery
} from 'services/aiphoneCloud';
import { UserType } from 'shared/utils/UserUtils';

// custom hook to fetch all users asynchronously in batches after the first page loads
const useGetUsers = (currentPage: number, pageSize: number, userType: UserType, firstBranchId?: any) => {
  switch (userType) {
    case UserType.GLOBAL_ROLE:
      return useGetUsersWithGlobalRolesQuery({
        qty: pageSize.toString(),
        page: currentPage.toString()
      });
    case UserType.AIPHONE_ROLE:
      return useGetUsersWithBranchRolesQuery({
        qty: pageSize.toString(),
        page: currentPage.toString()
      });
    case UserType.NO_BRANCH_ROLE:
      return useGetUsersWithNoBranchRolesQuery({
        qty: pageSize.toString(),
        page: currentPage.toString()
      });
    case UserType.NO_AIPHONE_ROLE:
      return useGetUsersByBranchPublicIdQuery(
        {
          qty: pageSize.toString(),
          page: currentPage.toString(),
          publicId: firstBranchId
        },
        { skip: !firstBranchId }
      );
    default:
      throw new Error('Invalid role');
  }
};

export default useGetUsers;
